/** @format */

import React from "react";
import { Controller } from "react-hook-form";
import "./PostOn.css";
const PostOn = ({ control }) => {
  return (
    <>
      <label htmlFor='textareaField'>Post on</label>
      <div style={{ display: "flex" }}>
        {/* Facebook checkbox */}
        <Controller
          name='postOn.facebook'
          control={control}
          render={({ field }) => (
            <div
              style={{
                display: "flex",
                // justifyContent: "space-around",
                gap: "15%",
                width: "30%",
                marginLeft: "5%",
              }}>
              <input type='checkbox' {...field} value='Facebook' />
              <p>Facebook</p>
            </div>
          )}
        />

        {/* App checkbox */}
        <Controller
          name='postOn.app'
          control={control}
          render={({ field }) => (
            <div
              style={{
                display: "flex",
                gap: "17%",
                width: "20%",
              }}>
              <input type='checkbox' {...field} value='App' />
              <p>App</p>
            </div>
          )}
        />

        {/* Telegram checkbox */}
        <Controller
          name='postOn.telegram'
          control={control}
          render={({ field }) => (
            <div
              style={{
                display: "flex",
                gap: "15%",
                width: "25%",
              }}>
              <input type='checkbox' {...field} value='Telegram' />
              <p>Telegram</p>
            </div>
          )}
        />
      </div>
    </>
  );
};

export default PostOn;
