/** @format */

import React from "react";

const StatusCell = ({ row }) => {
  let backgroundColor, color, statusBorder;

  switch (row.status) {
    case "active":
      backgroundColor = "#D1FAE5";
      color = "#065F46";
      statusBorder = "2px solid green";
      break;
    case "Active":
      backgroundColor = "#D1FAE5";
      color = "#065F46";
      statusBorder = "2px solid green";
      break;
    case "Expired":
      backgroundColor = "#FEE2E2";
      color = "#991B1B";
      statusBorder = "2px solid red";
      break;
    case "expired":
      backgroundColor = "#FEE2E2";
      color = "#991B1B";
      statusBorder = "2px solid red";
      break;
    default:
      backgroundColor = "gray";
      break;
  }

  const cellStyle = {
    window:"65px",
    height: "30px",
    alignItems: "center",
    textAlign: "center",
    display: "flex",
    gap: "0.5rem",
    textTransform: "capitalize",
    borderRadius: "4px",
    padding: "2px 12px 2px 8px",
    backgroundColor: backgroundColor,
    color: color,
  };
  const dot = {
    border: statusBorder, 
    borderRadius: "50%",
    height: "4px",
    width: "4px",
    backgroundColor: color,
  };
  
  const Statusdot = {
    display: "flex",
    alignItems: "center",
  };

  return (
    <div style={cellStyle}>
      <div style={Statusdot}>
        <div style={dot}></div>
      </div>
      {row.status}
    </div>
  );
};

export default StatusCell;
export const columns = [
  {
    name: "Name",
    selector: (row) => row?.name,
    maxWidth: "180px",
    style: { marginLeft:"20px",},
  },
  {
    name: "Category",
    selector: (row) => row?.category.name,
    maxWidth: "130px",
  },
  {
    name: "Description",
    selector: (row) => row?.description,
    maxWidth: "180px",
  },

  {
    name: "Created on ",
    selector: (row) => {
      const date = new Date(row?.created_at);
      return date.toISOString().split("T")[0];
    },

    maxWidth: "180px",
  },
  {
    name: "Expiry date",
    selector: (row) => {
      const date = new Date(row?.expiry_date);
      return date.toISOString().split("T")[0];
    },
    maxWidth: "180px",
  },
  {
    name: "Posted on",
    selector: (row) => {
      const capitalizedString = row?.post_on
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(", ");
      return capitalizedString;
    },
    maxWidth: "200px",
    wrap: true,
  },

  {
    name: "Status",
    id: "status",
    cell: (row) => <StatusCell row={row} />,
    maxWidth: "180px",
  },
];
