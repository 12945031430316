/** @format */

import React, { useState } from "react";
import Picker from "emoji-picker-react";
import Typography from "../../Atoms/Typography/Typography";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  CONSTANTS,
  CREATE_NOTIFICATION,
  ROUTES,
} from "../../../Helpers/Constants";
import Button from "../../Atoms/Button/Button";
import Container from "../../Atoms/Container/Container";
import "./CreateNotificationScreen.css";
import SubmitIcon from "../../../Assets/Icons/Submit.svg";

import PostDealMessage from "../PostDealMessage/PostDealMessage";
import { useNavigate } from "react-router-dom";
import { STYLES } from "../../../Helpers/StyleConstants";
const schema = yup.object().shape({
  [CONSTANTS.TEXTAREA]: yup.string().required("Textarea is required"),
});
const CreateNotificationScreen = () => {
  const navigate = useNavigate();

  const defaultValues = {
    [CONSTANTS.TEXTAREA]: "",
  };
  const [isOpen, setIsOpen] = useState(false);
  const [showemoji, setemojipicker] = useState(true);
  const [data, setData] = useState({});

  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    setIsOpen(true);
    setData({ description: data?.textareaField });
    console.log(data, "data");
    setemojipicker(false);
  };

  const handleCancel = () => {
    navigate(ROUTES?.PUSH_NOTIFICATION);
  };
  const onEmojiClick = (e) => {
    const currentText = getValues(CONSTANTS?.TEXTAREA) || "";
    const emojiToAdd = e.emoji;
    const newValue = currentText + emojiToAdd;

    setValue(CONSTANTS?.TEXTAREA, newValue);
  };
  return (
    <Container className={STYLES.FullScreen}>
      <Container className={STYLES.MainContent}>
        <Container className={STYLES.SearchComponent}>
          <Typography
            className={STYLES.CreateNotification_Heading}
            variant="h1"
          >
            {CONSTANTS.CREATE_PUSH_NOTIFICATION}
          </Typography>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <label htmlFor="textareaField" className={STYLES.TextArea_Label}>
                {CONSTANTS.PUSH_NOTIFICATION}
              </label>
              <Controller
                name={CONSTANTS.TEXTAREA}
                control={control}
                defaultValue={defaultValues[CONSTANTS.TEXTAREA]}
                render={({ field }) => (
                  <div>
                    <textarea
                      id="text-area"
                      {...field}
                      className={STYLES.TextArea}
                    />
                    {showemoji && <Picker onEmojiClick={onEmojiClick} />}
                  </div>
                )}
              />

              {errors[CONSTANTS.TEXTAREA]?.message && (
                <h5 className={STYLES.ERROR_MESSAGE}>
                  {errors[CONSTANTS.TEXTAREA]?.message}
                </h5>
              )}
            </div>
            <Container className={STYLES.Notification_Button}>
              <Button
                type="submit"
                className={STYLES.SendNotification_button}
                text={CONSTANTS.SEND}
              />
              <Button
                type="button"
                onClick={handleCancel}
                className={STYLES.CancelNotification_button}
                text={CONSTANTS.CANCEL}
              />
            </Container>
          </form>
        </Container>
      </Container>

      {isOpen && (
        <PostDealMessage
          setIsOpen={setIsOpen}
          data={data}
          heading={CONSTANTS?.POST_NOTIFICATION__HEADING}
          text={CONSTANTS?.POST_NOTIFICATION_MESSAGE}
          apiUrl={CREATE_NOTIFICATION}
          src={SubmitIcon}
          navigateToOnSend={ROUTES?.PUSH_NOTIFICATION}
          sendButtonText={CONSTANTS?.SEND}
          action="Send"
          navigateToOnCancel={`${ROUTES?.PUSH_NOTIFICATION}${ROUTES?.CREATE_NOTIFICATION}`}
        />
      )}
    </Container>
  );
};
export default CreateNotificationScreen;
