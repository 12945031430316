import React from "react";
import Typography from "../../Atoms/Typography/Typography";
import "./UserManagementDetails.css";
import { STYLES } from "../../../Helpers/StyleConstants";
const UserManagementDetails = ({
  Name,
  EmailAddress,
  JoinedDate,
  Points,
}) => {
  return (
    <div className="user-management">
      <ul className={STYLES.User_Manager_Detail}>
        <li>
          <Typography variant="p" className={STYLES.User_Manager_Detail_Text}>
            {Name}
          </Typography>
        </li>
        <li>
          <Typography variant="p" className={STYLES.User_Manager_Detail_Text}>
            {EmailAddress}
          </Typography>
        </li>
        <li>
          <Typography variant="p" className={STYLES.User_Manager_Detail_Text}>
            {JoinedDate}
          </Typography>
        </li>

        {/* <div className="PushNotificationLastTwoColumns"> */}
        <li>
          <Typography variant="p" className={STYLES.User_Manager_Detail_Text}>
            {Points}
          </Typography>
        </li>
       
      </ul>
    </div>
  );
};

export default UserManagementDetails;
