/** @format */
import SideBar from "../SideBar/SideBar";
import BreadCrums from "../../Atoms/BreadCrums/BreadCrums";
import "./Style.css";
import { useLocation } from "react-router-dom";
import { STYLES } from "../../../Helpers/StyleConstants";
const Layout = ({ children }) => {
  const location = useLocation();
  const breadCrumbs = location.pathname.split("/").filter(Boolean);
  const CrumbMappings = {
    PushNotification: "Push Notifications",
    UserManagement: "User Management",
    CreateNotification: "Create Push Notifications",
    HotDeals: "Hot Deals Management",
    HotDealsForm: "Create new Hot Deals Management",
    Dashboard: "Normal Deals",
  };
  const mappedCrumbs = breadCrumbs.map(
    (crumb) => CrumbMappings[crumb] || crumb
  );
  return (
    <div className='layout'>
      <div className={STYLES.Siderbar}>
        <SideBar />
      </div>

      <div className={STYLES.Display_Screen}>
        <BreadCrums crumbs={mappedCrumbs} breadCrumbs={breadCrumbs} />
        <div className='content'>{children}</div>
      </div>
    </div>
  );
};
export default Layout;
