// import ActionCell from "../../Atoms/ActionCell";

// export const columns = [
//   {
//     name: "Name",
//     selector: (row) => row?.name,
//     maxWidth: "180px",
//   },
//   {
//     name: "Email Address",
//     selector: (row) => row?.email,
//     maxWidth: "300px",
//     wrap: true,
//   },
//   {
//     name: "Joined Date",
//     selector: (row) => {
//       const date = new Date(row?.created_at);
//       return date.toISOString().split("T")[0];
//     },
//     maxWidth: "180px",
//   },
//   {
//     name: "Points",
//     selector: (row) => row?.points,
//     maxWidth: "180px",
//   },
//   {
//     name: "Action",
//     cell: (row) => <ActionCell row={row} />,
//     maxWidth: "180px",
//   },
// ];
import { Button } from "rsuite";
const ActionCell = ({ row, handleShowMessages }) => {
  const handleReset = () => {
    handleShowMessages();
  };

  return (
    <>
      <Button
        className="reset-point"
        disabled={row.points === 0}
        onClick={handleReset}
      >
        Reset Points
      </Button>
    </>
  );
};

const getColumns = (handleShowMessages) => [
  {
    name: "Name",
    selector: (row) => row?.name,
    maxWidth: "180px",
    style: { marginLeft:"20px",},
  },
  {
    name: "Email Address",
    selector: (row) => row?.email,
    maxWidth: "300px",
    wrap: true,
  },
  {
    name: "Joined Date",
    selector: (row) => {
      const date = new Date(row?.created_at);
      return date.toISOString().split("T")[0];
    },
    maxWidth: "180px",
  },
  {
    name: "Points",
    selector: (row) => row?.points,
    maxWidth: "180px",
  },
  {
    name: "Action",
    cell: (row) => (
      <ActionCell row={row} handleShowMessages={handleShowMessages} />
    ),
    maxWidth: "180px",
  },
];

export default getColumns;
