import React, { useState, useEffect, useRef } from "react";
import SortIcon from "../../../Assets/Icons/SortAscending.svg";
import "./Sort.css";
import CustomSelect from "../../Atoms/CustomSelect/CustomSelect";

const SortBox = ({ sort, setSort }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [isSelected, setisSelected] = useState(false);
  const [selectedSortOption, setSelectedSortOption] = useState(null);
  const sortBoxRef = useRef(null);
  // const [sorting, setSorting] = useState(null);
  const handleClick = () => {
    console.log("clicked");
    setShowOptions(!showOptions);
    setisSelected(true);
  };
  const handleDocumentClick = (event) => {
    if (sortBoxRef.current && !sortBoxRef.current.contains(event.target)) {
      setShowOptions(false);
      setisSelected(false);
    }
  };
  useEffect(() => {
    setSort(selectedSortOption);
  }, [selectedSortOption, setSort]);
  useEffect(() => {
    // setSorting(selectedSortOption);
    setShowOptions(false);
    setisSelected(false);
  }, [selectedSortOption]);
  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);
  const selectedClass = !isSelected
    ? "sort-box-inner-container"
    : "sort-box-inner-container-selected";

  return (
    <div className="sort-box-container" ref={sortBoxRef}>
      <div onClick={handleClick} className={selectedClass}>
        <img src={SortIcon} alt="Logo" />
        <p className="sort-box-text">Sort</p>
      </div>

      {showOptions && (
        <CustomSelect
          setSort={setSelectedSortOption}
          sort={selectedSortOption}
          setSorting={setSelectedSortOption}
          sorting={selectedSortOption}
        />
      )}
    </div>
  );
};

export default SortBox;
