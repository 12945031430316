/** @format */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../../Atoms/InputField/InputField";
import "./ForgotPasswordScreen.css";
import logo from "../../../Assets/logo.png";
import Container from "../../Atoms/Container/Container";
import Image from "../../Atoms/Image/Image";
import Button from "../../Atoms/Button/Button";
import { CONSTANTS, ROUTES } from "../../../Helpers/Constants";
import { ResetPasswordEmail } from "../../../Helpers/Functions";
import CustomAlert from "../../Atoms/CustomAlert/CustomAlert";
import { STYLES } from "../../../Helpers/StyleConstants";

const ForgotPasswordScreen = () => {
  const navigate = useNavigate();
  const [ErrorMessage, setErrorMessage] = useState(false);
  const [isError, setIsError] = useState(false);
  const defaultValues = {
    Email: "",
  };
  const [showAlert, setShowAlert] = useState(false);

  const schema = yup.object().shape({
    Email: yup
      .string()
      .email(CONSTANTS.CORRECT_EMAIL)
      .required(CONSTANTS.EMAIlVALUE)
      .max(100, CONSTANTS.EMAIL_MAX)
      .min(4, CONSTANTS.EMAIL_MIN),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    const { Email: email } = data;
    try {
      const responseData = await ResetPasswordEmail(email);
      responseData&&handleShowAlert();
    } catch (error) {
      console.error(error);
      if (error.message.includes("Authentication failed")) {
        setErrorMessage("Enter Correct Credentials");
      }
    }
  };
  const handleShowAlert = () => {
    setShowAlert(true);
  };

  const handleCloseAlert = () => {
    setShowAlert(false);
  };

  const formClass = isError ? STYLES.LOGIN_FORGET_ERROR : STYLES.LOGIN_FORGET;
  const InputFieldClass = isError
    ? STYLES.INPUT_FIELD_FORGET_ERROR
    : STYLES.INPUT_FILED_FORGET;
  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      navigate(ROUTES?.DASHBOARD);
    }
  });
  return (
    <>
      {showAlert && (
        <CustomAlert
          message="A new request for password has been sent to your email address. "
          onClose={handleCloseAlert}
        />
      )}
      <Container className={STYLES.LOGIN_FORGET_CONTAINER}>
        <Image src={logo} alt="Logo" className={STYLES.LOGO_FORGET} />
        <h2>Forgot your password? </h2>
        <p className={STYLES.RESET_FORGET_PASSWORD}>
          {CONSTANTS.FORGET_PASSWORD_TEXT}
        </p>
        <form className={formClass} onSubmit={handleSubmit(onSubmit)}>
          <InputField
            name="Email"
            register={register}
            field="Email"
            errors={errors}
            label={CONSTANTS.EMAIL_ADDRESS}
            className={InputFieldClass}
            setIsError={setIsError}
            type={CONSTANTS.TEXT}
          />
          <Button
            type={CONSTANTS.TYPE}
            className={STYLES.SIGNIN_FORM_SUBMIT}
            text={CONSTANTS.RESET_PASSWORD}
          />
          {ErrorMessage && (
            <p style={{ margin: "0px", color: "red" }}>{ErrorMessage}</p>
          )}
        </form>
        <p className={STYLES.FORGET_PASSWORD_BOTTOM_TEXT}>
          {CONSTANTS.SIGN_IN_BOTTOM_TEXT}
        </p>
      </Container>
    </>
  );
};
export default ForgotPasswordScreen;
