import React from "react";
import  DropdownArrowIcon from "./DropdownArrowIcon";

const DropdownIndicator = () => {
  return (
    <div style={{marginRight:4}}>
      <DropdownArrowIcon />
    </div>
  );
};

export default DropdownIndicator;
