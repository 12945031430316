/** @format */
import React, { useEffect } from "react";
import { useState } from "react";
import "./SearchBox.css";
import SearchIcon from "../../../Assets/Icons/Search.svg";
import SortBox from "../Sort/Sort";
import FilterBox from "../Filter/Filter";

const SearchBox = ({ sort, search, setSearch, setSort, setFilter }) => {
  const [catfilter, setCatFilter] = useState([]);
  useEffect(() => {
    setSort(sort);
    setFilter(catfilter);
  }, [sort, setSort, setFilter, catfilter]);
  const handleInputChange = (e) => {
    const newSearchTerm = e.target.value;
    setSearch(newSearchTerm);
  };

  return (
    <div className="search-box-container">
      <div className="search-box-inner">
        <input
          className="search-text"
          type="text"
          placeholder="Search"
          maxlength="20"
          onChange={handleInputChange}
        />
        <img src={SearchIcon} alt="Logo" className="search-icon" width={25} />
      </div>
      <div className="sort-box-inner">
        <SortBox sort={sort} setSort={setSort} />
      </div>
      <div className="filter-box-inner">
        <FilterBox setCatFilter={setCatFilter} />
      </div>
      <div></div>
    </div>
  );
};

export default SearchBox;
