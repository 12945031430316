/** @format */

import React, { useEffect, useState } from "react";
import Typography from "../Typography/Typography";
import SearchIcon from "../../../Assets/Notification/SearchIcon.svg";
import "./Style.css";
import Container from "../Container/Container";
import SortBox from "../../Molecules/Sort/Sort";
import HotDealFilter from "../../Molecules/HotDealFilter/HotDealFilter.js";
import { STYLES } from "../../../Helpers/StyleConstants.js";

const SearchField = ({
  text,
  sort,
  onSearch,
  setSort,
  setSearch,
  setFilter,
  sortingValue,
}) => {
  const [cartFilter, setCartFilter] = useState([]);
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setSearch(newValue);
    onSearch(newValue);
  };
  useEffect(() => {
    setSort(sort);
    setFilter(cartFilter);
  }, [sort, setSort, setFilter, cartFilter]);
  return (
    <Container className={STYLES.SearchComponent}>
      <Typography className={STYLES.CustomText} variant="h1">
        {text}
      </Typography>
      <Container className={STYLES.SearchField}>
        <div className="search-box-inner-container">
          <input
            className="search-text"
            type="text"
            placeholder="Search"
            maxLength={20}
            onChange={handleInputChange}
          />
          <img src={SearchIcon} alt="Logo" className="search-icon" width={25} />
        </div>
        {sortingValue === true && (
          <>
            <div className="sort-box-inner">
              <SortBox sort={sort} setSort={setSort} />
            </div>
            <div className="filter-box-inner">
              <HotDealFilter setCartFilter={setCartFilter} />
            </div>
          </>
        )}
      </Container>
    </Container>
  );
};

export default SearchField;
