/** @format */

import React, { useState, useEffect } from "react";
import "./HotDealsForm.css";
import { useForm, Controller } from "react-hook-form";
import PostDealMessage from "../../Organisms/PostDealMessage/PostDealMessage";
import { yupResolver } from "@hookform/resolvers/yup";
import SubmitIcon from "../../../Assets/Icons/Submit.svg";
import {
  GET_CATEGORIES_ENDPOINT,
  POST_HOT_DEAL_ENDPOINT,
  ROUTES,
} from "../../../Helpers/Constants";
import { useNavigate } from "react-router-dom";
import InputField from "../../Atoms/InputField/InputField";
import { CONSTANTS } from "./Constants";
import SelectField from "../../Atoms/SelectField/SelectField";
import Container from "../../Atoms/Container/Container";
import { defaultValues, getFormattedDate, schema } from "./FormSchema";
import PostOn from "../../Atoms/PostOn/PostOn";
import UploadAnImage from "../../Atoms/UploadAnImage/UploadAnImage";

import fetchWithHeaders from "../../../FetchInterceptor";
import { STYLES } from "../../../Helpers/StyleConstants";

const HotDealsForm = () => {
  const [data, setData] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchWithHeaders(GET_CATEGORIES_ENDPOINT);
        const result = await response.json();

        setCategoryData(
          result.data.map((item) => ({ value: item.id, label: item.name }))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [setValue]);

  const navigate = useNavigate();
  const onSubmit = async (data) => {
    setIsOpen(true);
    const socialApps = Object.keys(data.postOn).filter((key) => {
      return data.postOn[key] === true;
    });
    console.log(socialApps);
    setData({
      name: data?.Deal_Name,
      description: data?.Description,
      expiry_date: getFormattedDate(data?.Expiry_Date),
      post_on: data?.postOn,
      category_id: data?.Category?.value,
      images: [data?.ImageLink],
      link_1: data?.Link1,
      link_2: data?.Link2,
      link_3: data?.Link3,
      code: data?.code,
    });
  };
  return (
    <div className="deal-form-container">
      <div className="form-container">
        <form onSubmit={handleSubmit(onSubmit)} className="deal-form">
          <h2>Create new Hot Deals Management</h2>

          <InputField
            name="Deal_Name"
            register={register}
            field="Deal_Name"
            errors={errors}
            label={CONSTANTS.DEAL_NAME}
            type={CONSTANTS.TEXT}
            showAsterisk={true}
            maxLength={168}
          />

          <Controller
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <SelectField
                value={value}
                label={"Category"}
                placeholder={"Category"}
                error={error?.message}
                options={categoryData}
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(e);
                }}
              />
            )}
            name={"Category"}
            control={control}
          />
          <InputField
            name="code"
            register={register}
            field="code"
            errors={errors}
            label={CONSTANTS.CODE}
            type={"text"}
          />
          <InputField
            name="Expiry_Date"
            register={register}
            field="Expiry_Date"
            errors={errors}
            label={CONSTANTS.EXPIRY_DATE}
            type={"date"}
            minDate={getFormattedDate(new Date())}
            showAsterisk={true}
          />
          <UploadAnImage
            setValue={setValue}
            name={"ImageLink"}
            errors={errors}
          />
          <InputField
            name="Link1"
            register={register}
            field="Link1"
            errors={errors}
            label={CONSTANTS.LINK1}
            type={CONSTANTS?.TEXT}
            showAsterisk={true}
          />
          <InputField
            name="Link2"
            register={register}
            field="Link2"
            errors={errors}
            label={CONSTANTS.LINK2}
            type={CONSTANTS?.TEXT}
            showAsterisk={true}
          />
          <InputField
            name="Link3"
            register={register}
            field="Link3"
            errors={errors}
            label={CONSTANTS.LINK3}
            type={CONSTANTS?.TEXT}
            showAsterisk={true}
          />
          <Controller
            name="postOn"
            control={control}
            render={({ field }) => <PostOn control={control} />}
          />

          <div>
            <label htmlFor="textareaField">Description</label>
            <Controller
              name="Description"
              control={control}
              defaultValue={defaultValues[CONSTANTS.TEXTAREA]}
              render={({ field }) => (
                <textarea {...field} className={STYLES.TextAreaHotDeals} />
              )}
            />
          </div>
          {errors?.Description?.message && (
            <h5 className={STYLES.ERROR_MESSAGE}>
              {errors?.Description?.message}
            </h5>
          )}

          <Container className="footer-button">
            <button type="submit" className="create-new-deal-send-button">
              Send
            </button>
            <button
              onClick={() => {
                navigate(`${ROUTES?.HOT_DEALS}`);
              }}
              type="button"
              className="create-new-deal-cancel-button"
            >
              Cancel
            </button>
          </Container>
        </form>
      </div>
      {isOpen && (
        <PostDealMessage
          setIsOpen={setIsOpen}
          data={data}
          heading={CONSTANTS.POST_DEAL_HEADING}
          text={CONSTANTS.POST_NEW_DEAL_MESSAGE}
          apiUrl={POST_HOT_DEAL_ENDPOINT}
          src={SubmitIcon}
          navigateToOnSend={ROUTES.HOT_DEALS}
          sendButtonText={CONSTANTS?.SEND}
          action="Send"
          navigateToOnCancel={`${ROUTES.HOT_DEALS}${ROUTES.HOT_DEALS_FORM}`}
        />
      )}
    </div>
  );
};

export default HotDealsForm;
