/** @format */

import React from "react";
import Typography from "../../Atoms/Typography/Typography";
import UserIcon from "../../../Assets/Icons/UserCircle.svg";
import { CONSTANTS } from "../../../Helpers/Constants";
import "./NoUserToDisplay.css";
import { STYLES } from "../../../Helpers/StyleConstants";

const NoUserToDisplay = () => {
  
  return (
    <div>
      <div className={STYLES.No_User_to_Display_Message}>
        <img src={UserIcon} alt='Logo' className={STYLES.Hot_Deal_Tag_Logo} />
        <Typography variant='h5'>{CONSTANTS.NO_USER}</Typography>
        <Typography className={STYLES.NO_USER} variant='p'>
          {CONSTANTS.NO_USER_TO_BE_DISPLAYED}
        </Typography>

      </div>
    </div>
  );
};

export default NoUserToDisplay;
