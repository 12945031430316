/** @format */

import React, { useState, useEffect, useRef } from "react";
import FilterIcon from "../../../Assets/Icons/Icon.svg";
import "./Filter.css";
import CustomSelectWithCatagory from "../../Atoms/CustomSelectWithCatagory/CustomSelectWithCatagory";
const FilterBox = ({ setCatFilter }) => {
  const [showOptions, setShowOptions] = useState(false);
  const [filter, setFilter] = useState([]);
  const [isSelected, setisSelected] = useState(false);
  const [CheckBoxArray, setCheckBoxArray] = useState([]);
  const filterBoxRef = useRef(null);
  const handleClick = () => {
    setShowOptions(!showOptions);
    setisSelected(true);
  };
  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (
        filterBoxRef.current &&
        !filterBoxRef.current.contains(event.target)
      ) {
        setShowOptions(false);
        setisSelected(false);
      }
    };
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);
  useEffect(() => {
    setCatFilter(filter);
  }, [filter, setCatFilter]);
  const selectedClass = !isSelected
    ? "filter-box-inner-container"
    : "filter-box-inner-container-selected";

  return (
    <div className="filter-box-container" ref={filterBoxRef}>
      <div onClick={handleClick} className={selectedClass}>
        <img className="Filter_img" src={FilterIcon} alt="Logo" />
        <p>Filter</p>
      </div>
      {showOptions && (
        <CustomSelectWithCatagory
          setFilter={setFilter}
          setCheckBoxArray={setCheckBoxArray}
          CheckBoxArray={CheckBoxArray}
        />
      )}
    </div>
  );
};
export default FilterBox;
