/** @format */

import React, { useEffect, useState } from "react";
import ImageUpload from "../../../Assets/Assets/AttachedFileIcon.svg";
import "./UploadAnImage.css";
import uploadImage1 from "../../../Assets/UploadFile.svg";
import axios from "axios";
import Image from "../Image/Image";
import Typography from "../Typography/Typography";
import { IMAGE_UPLOAD_ENDPOINT } from "../../../Helpers/Constants";
import Container from "../Container/Container";
import { STYLES } from "../../../Helpers/StyleConstants";
const UploadAnImage = ({ setValue, name, errors }) => {
  const [imageData, setImageData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const getAccessToken = () => {
    return localStorage.getItem("accessToken");
  };
  useEffect(() => {
    if (errors[name]?.message) {
      setErrorMessage(errors[name]?.message);
    }
  }, [setErrorMessage, errors, name]);
  const handleUpload = async (event) => {
    const selectedFile = event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!allowedTypes.includes(selectedFile.type)) {
      setErrorMessage("Please upload a valid image file (JPEG, PNG, or GIF).");
      return;
    }
    setImageData(selectedFile.name);
    if (selectedFile) {
      let data = new FormData();
      var file = blobToFile(selectedFile, "userImage.jpg");
      data.append("file", file, "userImage.jpg");
      const accessToken = getAccessToken();
      if (accessToken) {
        try {
          const response = await axios.post(IMAGE_UPLOAD_ENDPOINT, data, {
            headers: {
              Authorization: "Bearer " + accessToken,
              "Content-Type": "multipart/form-data",
            },
          });
          setValue(name, response?.data?.image_url);
          setErrorMessage(null);
        } catch (error) {
          console.error("Error uploading image:", error);
          setErrorMessage("Error uploading image. Please try again.");
        }
      } else {
        console.log("Token Not Found");
      }
    }
  };

  const blobToFile = (theBlob, fileName) => {
    return new File([theBlob], fileName, {
      lastModified: new Date().getTime(),
      type: theBlob.type,
    });
  };

  const mainDivClickHandler = (e) => {
    document.getElementById("actual-btn");
  };

  const handleDragEnter = (e) => {
    console.log("select and image");
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const files = e.dataTransfer.files;
    if (files.length > 0) {
      handleFile(files[0]);
    }
  };

  const handleFile = (file) => {
    const allowedTypes = ["image/jpeg", "image/png", "image/gif"];
    if (!allowedTypes.includes(file.type)) {
      setErrorMessage(
        "Please upload a valid image file (JPEG, PNG, or GIF,png)."
      );
      return;
    }

    setImageData(file.name);
    let data = new FormData();
    var newFile = blobToFile(file, "userImage.jpg");

    data.append("file", newFile, "userImage.jpg");

    const accessToken = getAccessToken();
    if (accessToken) {
      axios
        .post(IMAGE_UPLOAD_ENDPOINT, data, {
          headers: {
            Authorization: "Bearer " + accessToken,
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          setValue(name, res?.data?.image_url);
        })
        .catch((error) => {
          console.error("Error uploading image:", error);
          setErrorMessage("Error uploading image. Please try again.");
        });
    } else {
      console.log("Token Not Found");
    }
  };

  return (
    <div
      className="upload-container"
      onClick={mainDivClickHandler}
      onDragEnter={handleDragEnter}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {imageData ? (
        <Container className="image-upload">
          <Image src={ImageUpload} alt="Uploaded" />
          <Typography className="image-link">{imageData}</Typography>
        </Container>
      ) : (
        <Container
          style={{
            border: "2px dashed #D1D5DB",
            height: "140px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingTop: "3%",
          }}
        >
          <img src={uploadImage1} alt="Upload icon" />
          <Container style={{ display: "flex" }}>
            <input type="file" id="actual-btn" hidden onChange={handleUpload} />
            <label className="labelFile" htmlFor="actual-btn">
              Upload a file
            </label>
            <p style={{ marginTop: "1rem", marginLeft: "0.2rem" }}>
              {" "}
              or drag and drop
            </p>
          </Container>
          <p style={{ color: "#6B7280" }}>PNG, JPG, GIF up to 10MB</p>
        </Container>
      )}
      {errorMessage && (
        <h5 className={STYLES.ERROR_MESSAGE}>
          {errorMessage ? errorMessage : errors[name]?.message}
        </h5>
      )}
    </div>
  );
};

export default UploadAnImage;
