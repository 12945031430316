/** @format */
import { RenewToken } from "./Helpers/Functions";
const fetchWithHeaders = async (url, options = {}) => {
  const token = localStorage.getItem("accessToken");
  const remember_me = localStorage.getItem("remember_me");
  const refresh_token = localStorage.getItem("refresh_token");
  const headers = {
    ...options.headers,
    "Content-Type": "application/json",
  };
  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }
  const modifiedOptions = {
    ...options,
    headers,
  };
  const response = await fetch(url, modifiedOptions);
  if (response.status === 401 && remember_me === "false") {
    localStorage.clear();
    window.location.reload();
    localStorage.setItem(
      "LogOut",
      "Your session has been expired. Please log in again."
    );
  }
  if (response.status === 401 && remember_me === "true") {
    try {
      const renewToken = await RenewToken(refresh_token);

      localStorage.setItem("accessToken", renewToken.access_token);
    } catch (error) {
      console.log("ErrorWithRenewToken", error);
    }
  }
  return response;
};
export default fetchWithHeaders;
