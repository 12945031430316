import React from "react";
import Typography from "../../Atoms/Typography/Typography";
import "./HotDealsDetails.css";
import { STYLES } from "../../../Helpers/StyleConstants";
const HotDealsDetails = ({
  Name,
  Descrption,
  CreatedOn,
  Status,
  Category,
  PostedOn,
  ExpiryDate,
}) => {
  return (
    <div className="hot-deals">
      <ul className={STYLES.Hot_Deal_deatils}>
        <li>
          <Typography variant="p" className={STYLES.Hot_Deal_Detail_Text}>
            {Name}
          </Typography>
        </li>
        <li>
          <Typography variant="p" className={STYLES.Hot_Deal_Detail_Text}>
            {Category}
          </Typography>
        </li>
        <li>
          <Typography variant="p" className={STYLES.Hot_Deal_Detail_Text}>
            {Descrption}
          </Typography>
        </li>

        {/* <div className="PushNotificationLastTwoColumns"> */}
        <li>
          <Typography variant="p" className={STYLES.Hot_Deal_Detail_Text}>
            {CreatedOn}
          </Typography>
        </li>
        <li>
          <Typography variant="p" className={STYLES.Hot_Deal_Detail_Text}>
            {ExpiryDate}
          </Typography>
        </li>
        <li>
          <Typography variant="p" className={STYLES.Hot_Deal_Detail_Text}>
            {PostedOn}
          </Typography>
        </li>
        <li>
          <Typography variant="p" className={STYLES.Hot_Deal_Detail_Text}>
            {Status}
          </Typography>
        </li>
        {/* </div> */}
      </ul>
    </div>
  );
};

export default HotDealsDetails;
