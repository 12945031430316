/** @format */

// NavigationConstants.js
import ExploreIcon from "../Assets/Assets/grayIcon/Tag.svg";
import GiftIcon from "../Assets/Assets/grayIcon/Gift.svg";
import UserIcon from "../Assets/Assets/grayIcon/Users.svg";
import Setting from "../Assets/Assets/grayIcon/Cog.svg";
import BellIcon from "../Assets/Notification/Bell.svg";
import TagIcon from "../Assets/Assets/BrownIcon/Tag.svg";
import GiftsIcon from "../Assets/Assets/BrownIcon/Gift.svg";
import UsersIcon from "../Assets/Assets/BrownIcon/Users.svg";
import Settings from "../Assets/Assets/BrownIcon/Cog.svg";
import BellIscon from "../Assets/Assets/BrownIcon/Bell.svg";
import { CONSTANTS, ROUTES } from "./Constants";

export const navigationItems = [
  {
    path: ROUTES.HOT_DEALS,
    label: CONSTANTS.HOT_DEALS,
    icon: { activeIcon: TagIcon, inactiveIcon: ExploreIcon },
  },
  {
    path: ROUTES.DASHBOARD,
    label: CONSTANTS.NORMAL_DEALS,
    icon: { activeIcon: GiftsIcon, inactiveIcon: GiftIcon },
  },
  {
    path: ROUTES.PUSH_NOTIFICATION,
    label: CONSTANTS.PUSH_NOTIFICATION,
    icon: { activeIcon: BellIscon, inactiveIcon: BellIcon },
  },
  {
    path: ROUTES.USER_MANAGEMENT,
    label: CONSTANTS.USER_MANAGEMENT,
    icon: { activeIcon: UsersIcon, inactiveIcon: UserIcon },
  },
  {
    path: ROUTES.SETTING,
    label: CONSTANTS.SETTINGS,
    icon: { activeIcon: Settings, inactiveIcon: Setting },
  },
];
