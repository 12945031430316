/** @format */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../../Atoms/InputField/InputField";
import "./SignIn.css";
import logo from "../../../Assets/logo.png";
import Container from "../../Atoms/Container/Container";
import Image from "../../Atoms/Image/Image";
import Button from "../../Atoms/Button/Button";
import { useNavigate } from "react-router-dom";
import { CONSTANTS, ROUTES } from "../../../Helpers/Constants";
import { signInUser } from "../../../Helpers/Functions";
import { useAlertContext } from "../../../Helpers/UseContext";
import CustomAlert from "../../Atoms/CustomAlert/CustomAlert";
import { STYLES } from "../../../Helpers/StyleConstants";
const SignIn = () => {
  const navigate = useNavigate();
  const [isError, setIsError] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState(false);
  const [isChecked, setisChecked] = useState(false);

  const defaultValues = {
    Email: "",
    Password: "",
  };

  const schema = yup.object().shape({
    Email: yup
      .string()
      .email(CONSTANTS.CORRECT)
      .required(CONSTANTS.EMAIlVALUE)
      .max(100, CONSTANTS.EMAIL_MAX)
      .min(4, CONSTANTS.EMAIL_MIN),
    Password: yup
      .string()
      .required(CONSTANTS.PASSWORD_VALUE)
      .min(6, CONSTANTS.PASSWORD_MIN)
      .max(100, CONSTANTS.PASSWORD_MAX),
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  const storeAccessToken = (accessToken, refresh_token, remember_me) => {
    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("refresh_token", refresh_token);
    localStorage.setItem("remember_me", remember_me);
  };
  const handleCheckboxChange = (e) => {
    setisChecked(e.target.checked);
  };
  const onSubmit = async (data) => {
    const { Email: username, Password: password } = data;

    const remember_me = isChecked;
    const refreshToken = localStorage.getItem("refresh_token");
    try {
      const responseData = await signInUser(
        username,
        password,
        remember_me,
        refreshToken
      );
      storeAccessToken(
        responseData.access_token,
        responseData.refreshToken,
        remember_me
      );

      // navigate(ROUTES?.DASHBOARD);
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        setErrorMessage("Enter Correct Credentials");
      }
    }
  };
  const formClass =
    isError || ErrorMessage ? STYLES.LOGIN_FORM_ERROR : STYLES.LOGIN_FORM;
  const InputFieldClass = isError
    ? STYLES.INPUT_FIELD_ERROR
    : STYLES.INPUT_FORM;
  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      navigate(ROUTES.DASHBOARD);
    }
  });
  const LoggedOut = localStorage.getItem("LogOut");

  const { showAlert, alertMessage, hideAlert } = useAlertContext();

  return (
    <>
      {showAlert && LoggedOut !== null ? (
        <CustomAlert
          message={alertMessage ? alertMessage : LoggedOut}
          onClose={hideAlert}
        />
      ) : null}

      <Container className={STYLES.LOGIN_CONTAINER}>
        <Image src={logo} alt="Logo" className={STYLES.LOGO} />
        <h2>Admin Portal </h2>
        <form className={formClass} onSubmit={handleSubmit(onSubmit)}>
          <InputField
            name="Email"
            register={register}
            field="Email"
            errors={errors}
            label={CONSTANTS.EMAIL_ADDRESS}
            className={InputFieldClass}
            setIsError={setIsError}
            // type={CONSTANTS.TEXT}
          />
          <InputField
            name="Password"
            register={register}
            field="Password"
            errors={errors}
            label={CONSTANTS.PASSWORD}
            className={InputFieldClass}
            setIsError={setIsError}
            type="password"
          />
          <Container className={STYLES.FORM_BUTTON}>
            <label className={STYLES.REMEMBER_ME}>
              <input
                type={CONSTANTS.CHECKBOX}
                className={STYLES.LOGIN_CHECKBOX}
                name={CONSTANTS.REMEMBER_ME}
                onChange={handleCheckboxChange}
              />
              <p style={{ margin: "0px" }}>Remember Me</p>
            </label>
            <a href={ROUTES.Forget_Password} className={STYLES.FORGET_BUTTON}>
              {CONSTANTS.FORGET_PASSWORD}
            </a>
          </Container>
          <Button
            type={CONSTANTS.TYPE}
            className={STYLES.SIGNIN_FORM_SUBMIT}
            text={CONSTANTS.SIGN_IN}
          />
          {ErrorMessage && (
            <p style={{ margin: "0px", color: "red" }}>{ErrorMessage}</p>
          )}
        </form>
        <p className={STYLES.SING_BOTTOM_TEXT}>
          {CONSTANTS.SIGN_IN_BOTTOM_TEXT}
        </p>
      </Container>
    </>
  );
};

export default SignIn;
