/** @format */
import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import DataTable from "react-data-table-component";
import SearchBox from "../../Molecules/SearchBox";
import { BASE_URL, CONSTANTS, SYNC_ENDPOINT } from "../../../Helpers/Constants";
import LoadingScreen from "../../Atoms/LoadingScreen/LoadingScreen";
import CustomPagination from "../../Molecules/CustomPagination";
import Typography from "../../Atoms/Typography/Typography";
import fetchWithHeaders from "../../../FetchInterceptor";
import NormalDealsDetails from "../../Molecules/NormalDealsDetail/NormalDealsDetail";
import { STYLES } from "../../../Helpers/StyleConstants";
import Button from "../../Atoms/Button/Button";
import SyncData from "../../Molecules/SyncData";
import CustomAlert from "../../Atoms/CustomAlert/CustomAlert";
import { useAlertContext } from "../../../Helpers/UseContext";
let limit = 50;
const columns = [
  {
    name: "Title",
    selector: (row) => row?.title,
    maxWidth: "180px",
    style: { marginLeft:"20px",},
  },
  {
    name: "Category",
    selector: (row) => row?.category?.name,
    maxWidth: "180px",
  },
  {
    name: "Store",
    selector: (row) => row?.store?.name,
    maxWidth: "180px",
  },
  {
    name: "Date",
    selector: (row) => row?.date,
    maxWidth: "180px",
  },
  {
    name: "Promo Code",
    selector: (row) => row?.promo_code,
    maxWidth: "180px",
  },
  {
    name: "Link",
    selector: (row) => row?.link,
    maxWidth: "180px",
  },
  {
    name: "Image Link",
    selector: (row) => row?.image_link,
    maxWidth: "180px",
  },
  {
    name: "Use Code",
    selector: (row) => row?.use_code,
    maxWidth: "180px",
  },
];
const customStyles = {
  headCells: {
    style: {
      color: "#1F2937",
      fontFamily: "Inter",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "32px",
      marginLeft: "20px",
    },
  },
  headRow: {
    style: {
      background: "#F9FAFB",
      width: "100%",
      display: "flex",
      justifyContent: "space-between",
    },
  },
};
const Dashboard = () => {
  let apiCalled = false;
  const [search, setSearch] = useState("");
  const [sort, setSort] = useState("");
  const [filter, setFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [count, setCount] = useState(0);
  const [index, setIndex] = useState(0);
  const [dealsData, setDealsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const { hideAlert, alert, setShowalert } = useAlertContext();
  const fetchDeals = async () => {
    try {
      const url = BASE_URL + `deal?limit=${limit}&index=${index}`;
      let filter_url = url;
      if (search) {
        filter_url = "&filter[search]=" + search;
      }
      if (search && sort) {
        filter_url = `&sort=created_at&sort=" ${sort}`;
      }
      if (sort) {
        filter_url = filter_url + "&sort=created_at&sort=" + sort;
      }
      if (filter) {
        let queryString = "";
        filter.forEach((filter) => {
          const { type, values } = filter;
          if (values && values.length > 0) {
            values.forEach((value) => {
              queryString += `&filter[${type}]=${encodeURIComponent(value)}`;
            });
          }
        });
        if (queryString.length > 0) {
          queryString = queryString.slice(1);
        }
        filter_url = filter_url + "&" + queryString;
      }
      const api_url = search
        ? url + "&filter[search]=" + encodeURIComponent(search)
        : `${filter_url}`;
      const response = await fetchWithHeaders(api_url, {
        method: "GET",
      });
      const result = await response.json();
      setCount(result?.totalCount);
      setDealsData(result?.data);
      setLoading(false);
      setShowalert(false);
    } catch (error) {
      console.error("Error fetching data for deals:", error);
      setLoading(false);
    }
  };

  const handleSync = async () => {
    try {
      setLoading(true);
      const response = await fetchWithHeaders(SYNC_ENDPOINT, {
        method: "POST",
      });
      const result = await response.json();
        if (result) {
          setShowalert(true);
        }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data for deals:", error);
      setLoading(false);
    }
  };
  useEffect(() => {
    if (filter?.length !== 0 || search !== "") {
      setIndex(0);
      setCurrentPage(1);
    } else if (currentPage !== 1) {
      setIndex(index + limit);
      setCurrentPage(currentPage + 1);
    }
    fetchDeals();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sort, filter]);
  useEffect(() => {
    fetchDeals();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index]);
  return (
    <>
      <div className="FullScreen">
        <div className="alert-popup-dashboard">
          {alert && (
            <CustomAlert
              message={CONSTANTS?.SYNC_MESSAGE}
              onClose={hideAlert}
            />
          )}
        </div>
        <div className="table-container">
          <div className="top-header-text">
            <h2>Normal Deals</h2>
            {count !== 0 ||
            (!apiCalled && (filter.length !== 0 || search !== "")) ? (
              <Button
                className={STYLES.SYNC_WITH_GOOGLE_SHEET_BUTTON_STYLE}
                onClick={handleSync}
                text={CONSTANTS.SYNC_WITH_GOOGLE_SHEET_TEXT}
              />
            ) : null}
          </div>
          <div className={STYLES.Add_New_Hot_DEAL}>
            <SearchBox
              search={search}
              setSearch={setSearch}
              setSort={setSort}
              setFilter={setFilter}
              sort={sort}
            />
          </div>
          {count === 0 && !apiCalled ? (
            <NormalDealsDetails
              Title={CONSTANTS.HOT_DEAL_NAME_TITLE}
              Category={CONSTANTS.CATEGORY}
              Store={CONSTANTS.NORMAL_DEAL_STORE}
              Date={CONSTANTS.NORMAL_DEAL_DATE}
              Promocode={CONSTANTS.NORMAL_DEAL_PROMO_CODE}
              Link={CONSTANTS.NORMAL_DEAL_LINK}
              ImageLink={CONSTANTS.NORMAL_DEAL_IMAGE_LINK}
              UseCode={CONSTANTS.NORMAL_DEAL_USE_CODE}
            />
          ) : null}
          {!loading ? (
            <>
              {count !== 0 ? (
                <div className="custom-table">
                  <DataTable
                    customStyles={customStyles}
                    noHeader={true}
                    columns={columns}
                    data={dealsData}
                    pagination
                    paginationPerPage={limit}
                    paginationRowsPerPageOptions={[10, 20, 30]}
                    paginationComponent={(props) => (
                      <CustomPagination
                        {...props}
                        currentPage={currentPage}
                        filter={filter}
                        limit={limit}
                        count={count}
                        index={index}
                        setIndex={setIndex}
                        onChangePage={(page) => {
                          setCurrentPage(page);
                        }}
                      />
                    )}
                  />
                </div>
              ) : !apiCalled && (filter.length !== 0 || search !== "") ? (
                <Typography className={STYLES.NO_SEARCH_DATA} variant="p">
                  {CONSTANTS.NO_SUCH_DATA}
                </Typography>
              ) : (
                <SyncData handleClick={handleSync} />
              )}
            </>
          ) : (
            <LoadingScreen />
          )}
        </div>
      </div>
    </>
  );
};
export default Dashboard;
