// // /** @format */

import React, { useState, useEffect } from "react";
import SearchField from "../../Atoms/Search/SearchIField";
import {
  CONSTANTS,
  ROUTES,
  USER_MANAGEMENT_DATA,
} from "../../../Helpers/Constants";
import Container from "../../Atoms/Container/Container";
import DataTable from "react-data-table-component";
import CustomPaginaton from "../../Molecules/CustomPagination";
import getColumns from "./UserManagementColumns";
import LoadingScreen from "../../Atoms/LoadingScreen/LoadingScreen";
import UserManagementDetails from "../../Molecules/UserManagementDetails/UserManagementDetails";
import NoUserToDisplay from "../../Molecules/NoUserToDisplay/NoUserToDisplay";
import "./UserManagement.css";
import Typography from "../../Atoms/Typography/Typography";
import SubmitIcon from "../../../Assets/Icons/Submit.svg";
import fetchWithHeaders from "../../../FetchInterceptor";
import { STYLES } from "../../../Helpers/StyleConstants";
import PostDealMessage from "../PostDealMessage/PostDealMessage";
import CustomAlert from "../../Atoms/CustomAlert/CustomAlert";
import { useAlertContext } from "../../../Helpers/UseContext";
const UserManagement = () => {
  const { hideAlert, alert, setShowalert } = useAlertContext();
  const [count, setCount] = useState(0);
  const [usersData, setUsersData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [index, setIndex] = useState(0);

  const getAccessToken = () => {
    return localStorage.getItem("accessToken");
  };

  let limit = 5;
  const customStyles = {
    headCells: {
      style: {
        color: "#1F2937",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "32px",
        marginLeft: "20px",
      },
    },
    headRow: {
      style: {
        background: "#F9FAFB",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      },
    },
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const accessToken = getAccessToken();

        // const url = USER_MANAGEMENT_DATA;
        const url = `${USER_MANAGEMENT_DATA}?limit=${limit}&index=${index}`;

        const api_url = search ? `${url}?filter[search]=${search}` : url;

        const response = await fetchWithHeaders(api_url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });

        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new Error("Response is not in JSON format");
        }

        const result = await response.json();

        setCount(result?.totalCount);

        setUsersData(result?.data);
        setShowalert(false);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data for users:", error);
        setLoading(false);
      }
    };
    if (!apiCalled || search) {
      setCurrentPage(currentPage + 1);

      fetchUsers();
    }
    setCurrentPage(currentPage + 1);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, apiCalled, index, filter]);

  const handleSearch = (newSearch) => {
    setSearch(newSearch);
    setApiCalled(false);
  };
  const handleShowMessages = () => {
    setIsOpen(true);
  };

  const columns = getColumns(handleShowMessages);
  return (
    <Container className={STYLES.FullScreen}>
      <div className="alert-popup">
        {alert && (
          <CustomAlert message={CONSTANTS?.RESET_MESSAGE} onClose={hideAlert} />
        )}
      </div>
      <Container className={STYLES.MainContent}>
        <Container className={STYLES.User_Manager_Search}>
          <SearchField
            text={CONSTANTS.USER_MANAGEMENT}
            onSearch={handleSearch}
            setSort={setSort}
            sort={sort}
            setSearch={setSearch}
            setFilter={setFilter}
            sortingValue={false}
          />
        </Container>
        {count === 0 && !apiCalled ? (
          <UserManagementDetails
            Name={CONSTANTS.HOT_DEAL_NAME_TITLE}
            EmailAddress={CONSTANTS.EMAIL_ADDRESS}
            JoinedDate={CONSTANTS.JOINED_DATE}
            Points={CONSTANTS.POINTS}
          />
        ) : null}

        {!loading ? (
          count !== 0 ? (
            <div className="custom-table">
              <DataTable
                customStyles={customStyles}
                noHeader={true}
                columns={columns}
                data={usersData}
                pagination
                paginationPerPage={limit}
                paginationRowsPerPageOptions={[10, 20, 30]}
                onChangePage={(page) => setCurrentPage(page)}
                paginationComponent={(props) => (
                  <CustomPaginaton
                    {...props}
                    limit={limit}
                    count={count}
                    index={index}
                    currentPage={currentPage}
                    setIndex={setIndex}
                  />
                )}
              />
            </div>
          ) : !apiCalled && !search ? (
            <NoUserToDisplay />
          ) : (
            <Typography className={STYLES.NO_SEARCH_DATA} variant="p">
              {CONSTANTS.NO_SUCH_DATA}
            </Typography>
          )
        ) : (
          <LoadingScreen />
        )}
      </Container>
      {isOpen && (
        <PostDealMessage
          setIsOpen={setIsOpen}
          apiUrl={""}
          data={usersData}
          heading={CONSTANTS?.RESET_POINT_HEADING}
          text={CONSTANTS?.RESET_POINT_TEXT}
          src={SubmitIcon}
          navigateToOnSend={ROUTES?.USER_MANAGEMENT}
          sendButtonText={CONSTANTS?.RESET}
          navigateToOnCancel={ROUTES?.USER_MANAGEMENT}
          action="resetPopup"
        />
      )}
    </Container>
  );
};

export default UserManagement;
