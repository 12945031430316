/** @format */

export const CONSTANTS = {
  EMAIL_ADDRESS: "Email address",
  EMAIL: "Email",
  PASSWORD: "Password",
  CORRECT: "Enter correct email address",
  REMEMBER_ME: "remember-me",
  EMAIlVALUE: "Enter an Email",
  EMAIL_MAX: "Email is too long",
  EMAIL_MIN: "Email is too short",
  JOINED_DATE: "Joined date",
  POINTS: "Points",
  PASSWORD_VALUE: "Enter a password",
  PASSWORD_MIN: "Password must be at least 6 characters",
  PASSWORD_MAX: "Password is too long",
  FORGET_PASSWORD: " Forgot your Password?",
  SIGN_IN: "Sign in",
  SIGN_IN_BOTTOM_TEXT: "©2024 iFrugal. All rights reserved",
  TYPE: "submit",
  CHECKBOX: "checkbox",
  TEXT: "text",
  CHANGE_PASSWORD: "Change Password",
  CORRECT_EMAIL: "Enter correct email address",
  FORGET_PASSWORD_TEXT:
    " Enter your email address and we'll send you a link to reset your password.",
  RESET_PASSWORD: "Reset Password",
  PASSWORD_LABEL: "Password",
  CONFIRM_PASSWORD: "Confirm Password",
  PASSWORD_DO_NOT_MATCH: "Passwords do not match!",
  CONFIRM: "CONFIRM",
  PUSH_NOTIFICATION: "Push Notifications",
  NOTIFICATION_NAME: "Notification name",
  DESCRIPTION: "Description",
  SENT_ON: "Sent on",
  STATUS: "Status",
  NOTIFICATION_TEXT: "No Notifications created yet!",
  NOTIFICATION_DISPLAYED: "No notification to be displayed!",
  NOTIFICATION_BUTTON: "Create new Push Notifications",
  CREATE_PUSH_NOTIFICATION: "Create new Push Notifications",
  HOT_DEALS: "Hot Deals Management",
  NORMAL_DEALS: "Normal Deals",
  USER_MANAGEMENT: "User Management",
  SETTING: "Settings",
  SETTINGS: "Settings",
  SEND: "Send",
  CANCEL: "Cancel",
  TEXTAREA: "textareaField",
  LOGOUT: "Logout",
  HotDeals: "Hot Deals Management",
  CREATE_NEW_DEAL_BUTTON: "Create New Deal",
  SYNC_WITH_GOOGLE_SHEET_TEXT: "Sync with Google Sheet",
  SYNC_MESSAGE: "Data has been synced successfully from the google sheet",
  NO_DEAL: "No Deal",
  NO_USER: "No Users",
  NO_DEAL_TO_BE_DISPLAYED: "No deal to be displayed!",
  NO_USER_TO_BE_DISPLAYED: "No user to be displayed!",
  POSTED_On: "Posted on",
  Expiry_Date: "Expiry date",
  Create_On: "Created on ",
  HOT_DEAL_NAME_TITLE: "Name",
  CATEGORY: "Category",
  POST_DEAL_HEADING: "Post Deal",
  FORGET_PASSWORD_LINK_EXP: "Your link is Expired",
  OLD_PASSWORD: "Old Password ",
  CHANGE: "Change",
  RESET_PASSWORD_MESSAGE:
    "Your password has been changed, please login with new password. ",
  OLD_PASSWORD_WRONG: "The old password you entered is wrong",
  NO_SUCH_DATA: "No Such Data Found",
  TOKEN_EXPIRED_MESSAGE: "Your token is expired",
  OLD_PASSWORD_REQUIRED: "Old Password is required",
  CONFIRM_PASSWORD_REQUIRED: "Confirm Password is required",
  POST_NOTIFICATION_MESSAGE:
    "Are you sure you want to send this notification? It will be send to all the registered users on the platform. This action cannot be undone.",
  POST_NOTIFICATION__HEADING: "Send Notification",
  NORMAL_DEAL_TITLE: "Title",
  NORMAL_DEAL_STORE: "Store",
  NORMAL_DEAL_DATE: "Date",
  NORMAL_DEAL_PROMO_CODE: "Promo code",
  NORMAL_DEAL_LINK: "Link",
  NORMAL_DEAL_IMAGELINK: "Image Link",
  NORMAL_DEAL_USECODE: "Use code",
  RESET_POINT_TEXT: "Are you sure you want to reset the points for this user?",
  RESET_POINT_HEADING: "Reset Point",
  RESET: "Reset",
  RESET_MESSAGE: "You have reset the points successfully",
};

export const STYLES = {
  REMEMBERME: "remember-me",
  LOGIN_FORM_ERROR: "login-form-errors",
  LOGIN_FORM: "login-form",
  INPUT_FIELD_ERROR: "input-form-field-error",
  INPUT_FORM: "input-form-field",
  LOGIN_CONTAINER: "login-container",
  FORGET_BUTTON: "forgotbutton",
  SIGNIN_FORM_SUBMIT: "signin-form-submit",
  SING_BOTTOM_TEXT: "signin-form-bottom-text",
  LOGIN_CHECKBOX: "login-form-checkbox",
  FORM_BUTTON: "formbuttons",
  LOGO: "logo",
  LOGIN_FORGET_ERROR: "login-form-errors-forgot-pass",
  LOGIN_FORGET: "login-form-forgot-pass",
  INPUT_FIELD_FORGET_ERROR: "input-form-field-error-forgot-pass",
  INPUT_FILED_FORGET: "input-form-field-forgot-pass",
  LOGIN_FORGET_CONTAINER: "login-container-forgot-pass",
  LOGO_FORGET: "logo-forgot-pass",
  RESET_FORGET_PASSWORD: "reset-password-email",
  FORGET_PASSWORD_BOTTOM_TEXT: "forgot-pass-bottom-text",
  LOGIN_CREATE_PASSWORD_ERROR: "login-form-errors-create-pass",
  CREATE_LOGIN_PASSWORD: "login-form-create-pass",
  CREATE_NEWPASSWORD_CONTAINER: "create-newpass-container",
  Main: "Main",
  LogoImg: "LogoImg",
  SidebarIcon: "SideBarIcon",
  FullScreen: "FullScreen",
  MainContent: "MainContent",
  Header: "Header",
  Siderbar: "Sidebar",
  Navbar: "Navbar",
  SideBarItem: "SidebarItem",
  SearchComponent: "Search",
  SearchIcon: "SearchIcon",
  CustomText: "CustomText",
  SearchField: "SearchField ",
  InputField: "InputField",
  NotificationDescription: "NotificationDescription",
  CreateNotification: "CreateNotification",
  NotifictationMessage: "NotificationMessage",
  ActiveLink: "ActiveLink",
  ActiveText: "ActiveText",
  TextArea: "text_area",
  TextAreaHotDeals: "Text_Area_Hot_Deals",
  TextArea_Label: "textarea-label",
  CreateNotification_Heading: "create-notification-heading",
  Notification_Button: "notification-button",
  CREATENOTIFICATIONBUTTON: "Create-notification-button",
  SendNotification_button: "Send-notification-button",
  CancelNotification_button: "Cancel-notification-button",
  ERROR_MESSAGE: "error-message",
  BreadCrums: "Breadcrums",
  UserProfile: "user-profile",
  ProfileDetail: "profiledetail",
  ProfileName: "profile-name",
  Logout: "logout",
  ASTERIK_SYMBOL: "asterik-symbol",
  DASHBOARD_CREATE_NEW_DEAL_BUTTON: "dashboard-create-new-deal-button",
  ICON_CROSS_FORGOT_PASSWORD: "icon-cross-forgot-password",
  ALERT_MESSAGE_FORGOT_PASSWORD: "alert-message-forgot-password",
  Hot_NEW_DEAL_BUTTON: "hot-create-new-deal-button",
  Create_hot_Deals_Button: "empty-table-hot-new-deal-button ",
  Create_Empty_Hot_Deal_button: "empty-hot-deal-button",
  Hot_Deal_deatils: "Hot_Deals_details",
  Hot_Deal_Detail_Text: "hot-deal-details-text",
  User_Manager_Detail_Text: "user-manager-details-text",
  User_Manager_Detail: "user-manager-details",
  Create_Hot_Deal_Message: "create-new-hot-deal-message-container",
  Hot_Deal_Tag_Logo: "hotdeal-tag-logo ",
  No_deal: "no-deals",
  NO_USER: "no-user",
  Add_New_Hot_DEAL: "Add-new-hot-deal",
  User_Manager_Search: "User-manager-search",
  DashBoardNewDealButton: "dashboard-new-deal-Button",
  Change_Password: "change-password-link",
  Setting_Title: "setting-title",
  Setting_Form: "setting-form",
  Change_Password_Title: "change-password-title",
  Reset_Password_Error: "reset-password-error ",
  Reset_Password_form: "reset-form-password",
  Reset_Button: "reset-button",
  Change_Reset_button: "change-reset-button",
  No_User_to_Display_Message: "no-user-to-display-message",
  NO_SEARCH_DATA: "no-data-on-search",
  Setting_Color: "seeting-background-color",
  Search_box: "search-box",
  Display_Screen: "display-screen",
  ProfilePictue: "profile-picture",
  Notification_Details: "notification-details",
  Push_Notification_Detail_Text: "push-notification_detail_text",
  Add_New_Notification: "add-new-notifcation",
  Normal_Deals: "normal-deals",
  Normal_Deals_Details_Text: "normal-deal-details-text",
  Normal_Deals_details: "normal-deals-details",
  PROFILE_PIC: "profile-pic",
  Add_New_Normal_Deal: "add-new-normal-deal",
  Normal_Deal_Top_Header: "normal-deal-top-header-text",
  NORMAL_DEAL_IMAGE_LINK: "Image Link",
  NORMAL_DEAL_USE_CODE: "Use code",
  TOKEN: "token",
};
export const ROUTES = {
  Forget_Password: "/forgotPassword",
  CREATE_NORMAL_DEALS: "/create-normal-deal",
  CREATE_NEW_PASSWORD: "/CREATE_NEW_PASSWORD",
  HOT_DEALS: "/HotDeals",
  HOT_DEALS_FORM: "/HotDealsForm",
  NORMAL_DEALS: "/NormalDeals",
  PUSH_NOTIFICATION: "/PushNotification",
  USER_MANAGEMENT: "/UserManagement",
  SETTING: "/Settings",
  NORMAL_DEALS_FORM: "/CreateNormalDeal",
  POST_DEAL_MESSAGE: "/PostDealMessage",
  DASHBOARD: "/Dashboard",
  CREATE_NOTIFICATION: "/create-notifications",
  Reset_Password: "/ResetPassword",
};
export const BASE_URL="http://ifrugal-staging-lb-858770517.us-east-1.elb.amazonaws.com/v1/";
// export const BASE_URL = process.env.REACT_APP_BACKEND_URL;

export const API_URL_SIGN_IN = BASE_URL + "user/signin";

export const API_URL_RESET_PASSWORD_EMAIL = BASE_URL + "user/send-email";

export const RESET_PASSWORD_REQUEST = BASE_URL + "user/reset-password";
export const RENEW_TOKEN = BASE_URL + "user/renew-token";
export const SYNC_ENDPOINT = BASE_URL + "deal";
export const POST_DEAL_ENDPOINT = BASE_URL + "deal";
export const POST_HOT_DEAL_ENDPOINT = BASE_URL + "hot-deals";
export const GET_CATEGORIES_ENDPOINT = BASE_URL + "categories";
export const IMAGE_UPLOAD_ENDPOINT = BASE_URL + "hot-deals/upload/image";
export const USER_MANAGEMENT_DATA = BASE_URL + "user";
export const ERROR_MESSAGES = {
  AUTH_FAILED: "Authentication failed:",
  ERROR_AUTH: "Error during authentication:",
};
export const CREATE_NOTIFICATION =
  "http://ifrugal-staging-lb-858770517.us-east-1.elb.amazonaws.com/v1/notifications";
export const PROFILE_ENDPOINT =
  "http://ifrugal-staging-lb-858770517.us-east-1.elb.amazonaws.com/v1/user/admin/profile";

export const GET_STORE_ENDPOINT =
  "http://ifrugal-staging-lb-858770517.us-east-1.elb.amazonaws.com/v1/Store";
