/** @format */

import React from "react";

const StatusCell = ({ row }) => {
  let backgroundColor, color, statusBorder;

  switch (row.status) {
    case "sent":
      backgroundColor = "#D1FAE5";
      color = "#065F46";
      statusBorder = "2px solid green";
      break;
    case "Sent":
      backgroundColor = "#D1FAE5";
      color = "#065F46";
      statusBorder = "2px solid green";
      break;
    case "failed":
      backgroundColor = "#FEE2E2";
      color = "#991B1B";
      statusBorder = "2px solid red";
      break;
    case "Failed":
      backgroundColor = "#FEE2E2";
      color = "#991B1B";
      statusBorder = "2px solid red";
      break;
    default:
      backgroundColor = "gray";
      break;
  }

  const cellStyle = {
    window:"65px",
    height: "30px",
    alignItems: "center",
    textAlign: "center",
    display: "flex",
    gap: "0.5rem",
    textTransform: "capitalize",
    borderRadius: "4px",
    padding: "2px 12px 2px 8px",
    backgroundColor: backgroundColor,
    color: color,
  };
  const dot = {
    border: statusBorder, 
    borderRadius: "50%",
    height: "4px",
    width: "4px",
    backgroundColor: color,
  };
  const Statusdot = {
    display: "flex",
    alignItems: "center",
  };

  return (
    <div style={cellStyle}>
      <div style={Statusdot}>
        <div style={dot}></div>
      </div>
      {row.status}
    </div>
  );
};

export default StatusCell;

export const columns = [
  {
    name: "Description",
    selector: (row) => row?.description,
    maxWidth: "40%",
    style: { marginLeft:"20px",},
    
  },

  {
    name: "Sent on",
    selector: (row) => row?.sent_on,
    maxWidth: "180px",
  },
  {
    name: "Status",
    id: "status",
    cell: (row) => <StatusCell row={row} />,
    maxWidth: "180px",
  },
];
