/** @format */

import React, { useEffect } from "react";
import PreButton from "../../../Assets/Icons/ChevronLeft.svg";
import "./CustomPagination.css";
const CustomPagination = ({
  // rowCount
  index,
  limit,
  setIndex,
  count,
  currentPage,
  onChangePage,
}) => {
  const totalPages = Math.ceil(count / limit);
  const startIndex = (currentPage - 1) * limit + 1;
  const endIndex = Math.min(currentPage * limit, count);
  const numberOfRowsOnCurrentPage = endIndex - startIndex + 1;
  const handlePrev = () => {
    if (currentPage > 1) {
      const newIndex = Math.max(0, index - limit);

      onChangePage(currentPage - 1, newIndex);
      setIndex(index - limit);
    }
  };
  const handleNext = () => {
    if (currentPage < totalPages) {
      const newIndex = index + limit;

      onChangePage(currentPage + 1, newIndex);
      setIndex(newIndex);
    }
  };
  useEffect(() => {}, [currentPage]);
  return (
    <div className="custom-pagination">
      <div className="pagination-left">
        <span>{`Showing ${numberOfRowsOnCurrentPage} to ${limit} of ${count} results`}</span>{" "}
        {/* <span>{textToDisplay}</span>{" "} */}
      </div>
      <div className="pagination-right">
        <button
          className="pagination_button"
          onClick={handlePrev}
          disabled={currentPage === 1}
        >
          <img src={PreButton} alt="" />
        </button>
        <button className="centerpage">{currentPage}</button>
        <button
          className="pagination_button-next"
          onClick={handleNext}
          disabled={currentPage === totalPages}
        >
          <img src={PreButton} className="rotate-icon" alt="" />
        </button>
      </div>
    </div>
  );
};
export default CustomPagination;
