/** @format */

export const CONSTANTS = {
  DEAL_NAME: "Deal Name",
  TEXT: "text",
  EXPIRY_DATE: "Expiry Date",
  CODE: "Code",
  PROMO_CODE: "PromoCode",
  PROMO_CODE_TITLE: "Promo Code",
  LINK1: "Link1",
  LINK2: "Link2",
  LINK3: "Link3",
  URL: "url",
  IMAGE_LINK: "ImageLink",
  IMAGE_LINK_TITLE: "Image Link",
  USE_CODE_TITLE: "Use Code",
  USE_CODE: "UseCode",
  TITLE_MAX: "Title is too long",
  PROMOCODE_MAX: "Promocode is too long",
  USECODE_MAX: "Use Code is too long",
  TEXTAREA: "textareaField",
  POST_DEAL_HEADING: "Post Deal",
  POST_NEW_DEAL_MESSAGE: "Are you sure you want to post this deal?",
  DESCRIPTION_MAX: "Description is too long",
  SEND: "Send",
};
