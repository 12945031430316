/** @format */

import React from "react";
import TagIcon from "../../../Assets/Icons/Tag.svg";
import Typography from "../../Atoms/Typography/Typography";
import Button from "../../Atoms/Button/Button";
import { useNavigate } from "react-router-dom";
import Image from "../../Atoms/Image";
import { CONSTANTS, ROUTES } from "../../../Helpers/Constants";
import "./CreateHotDeals.css";
import { STYLES } from "../../../Helpers/StyleConstants";

const CreateHotDeals = ({ normalDeals }) => {
  const navigate = useNavigate();
  const handleHotDealClick = () => {
    navigate(`${ROUTES.HOT_DEALS}${ROUTES.HOT_DEALS_FORM}`);
  };
  return (
    <div>
      <div className={STYLES.Create_Hot_Deal_Message}>
        <Image src={TagIcon} alt='Logo' className={STYLES.Hot_Deal_Tag_Logo} />
        <Typography variant='h5'>{CONSTANTS.NO_DEAL}</Typography>
        <Typography className={STYLES.No_deal} variant='p'>
          {CONSTANTS.NO_DEAL_TO_BE_DISPLAYED}
        </Typography>

        {normalDeals === false && (
          <Button
            className={STYLES.Create_Empty_Hot_Deal_button}
            text={CONSTANTS.CREATE_NEW_DEAL_BUTTON}
            onClick={handleHotDealClick}
          />
        )}
      </div>
    </div>
  );
};

export default CreateHotDeals;
