/** @format */
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SearchField from "../../Atoms/Search/SearchIField";
import { CONSTANTS, ROUTES, BASE_URL } from "../../../Helpers/Constants";
import Container from "../../Atoms/Container/Container";
import DataTable from "react-data-table-component";
import CustomPaginaton from "../../Molecules/CustomPagination";
import { columns } from "./HotDealsColumns";
import Button from "../../Atoms/Button/Button";
import LoadingScreen from "../../Atoms/LoadingScreen/LoadingScreen";
import HotDealsDetails from "../../Molecules/HotDealsDetail/HotDealsDetails";
import CreateHotDeals from "../../Molecules/CreateHotDeals/CreateHotDeals";
import "./HotDeals.css";
import fetchWithHeaders from "../../../FetchInterceptor";
import Typography from "../../Atoms/Typography/Typography";
import { STYLES } from "../../../Helpers/StyleConstants";
const HotManagementDeals = () => {
  const [apiCalled, setApiCalled] = useState(false);
  const [count, setCount] = useState(0);
  const [index, setIndex] = useState(0);
  const [dealsData, setDealsData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [sortedData, setSortedData] = useState([]);
  const [sort, setSort] = useState("");
  const [filter, setFilter] = useState([]);
  const navigate = useNavigate();
  const getAccessToken = () => {
    return localStorage.getItem("accessToken");
  };
  let limit = 5;
  const customStyles = {
    headCells: {
      style: {
        color: "#1F2937",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "32px",
        marginLeft: "20px",
      },
    },
    headRow: {
      style: {
        background: "#F9FAFB",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      },
    },
  };

  const fetchDeals = async () => {
    try {
      const accessToken = getAccessToken();
      const url = BASE_URL + `hot-deals?limit=${limit}&index=${index}`;
      let filter_url = url;
      if (search) {
        filter_url = "&filter[search]=" + search;

        // url;
      }
      if (search && sort) {
        filter_url = filter_url + "&sort=created_at&sort=" + sort;
      }
      if (sort) {
        filter_url = filter_url + "&sort=created_at&sort=" + sort;
      }
      if (filter) {
        let queryString = "";
        filter.forEach((filter) => {
          const { type, values } = filter;
          if (values && values.length > 0) {
            values.forEach((value) => {
              queryString += `&filter[${type}]=${encodeURIComponent(value)}`;
            });
          }
        });
        if (queryString.length > 0) {
          queryString = queryString.slice(1);
        }
        filter_url = filter_url + "&" + queryString;
      }
      const api_url = search
        ? url + "&filter[search]=" + search
        : `${filter_url}`;
      const response = await fetchWithHeaders(api_url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "application/json",
        },
      });
      const result = await response.json();
      setCount(result?.totalCount);
      setDealsData(result?.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data for deals:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (filter?.length !== 0 || search !== "") {
      setIndex(0);
      setCurrentPage(1);
    } else if (currentPage !== 1) {
      setIndex(index + limit);
      setCurrentPage(currentPage + 1);
    }
    fetchDeals();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, sort, filter]);
  useEffect(() => {
    fetchDeals();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, currentPage, limit]);
  const handleSearch = (newSearch) => {
    setSearch(newSearch);
    setApiCalled(false);
  };
  useEffect(() => {
    if (dealsData) {
      const sorted = [...dealsData];
      setSortedData(sorted);
    }
  }, [dealsData]);
  return (
    <Container className={STYLES.FullScreen}>
      <Container className={STYLES.MainContent}>
        <Container className={STYLES.Add_New_Hot_DEAL}>
          <SearchField
            text={CONSTANTS.HOT_DEALS}
            onSearch={handleSearch}
            setSearch={setSearch}
            setSort={setSort}
            sort={sort}
            setFilter={setFilter}
            sortingValue={true}
          />
          {count !== 0 ||
          (!apiCalled && (filter.length !== 0 || search !== "")) ? (
            <Button
              className={STYLES.Create_hot_Deals_Button}
              onClick={() => {
                navigate(`${ROUTES?.HOT_DEALS}${ROUTES?.HOT_DEALS_FORM}`);
              }}
              text={CONSTANTS.CREATE_NEW_DEAL_BUTTON}
            />
          ) : null}
        </Container>

        {count === 0 && !apiCalled ? (
          <HotDealsDetails
            Name={CONSTANTS.HOT_DEAL_NAME_TITLE}
            Category={CONSTANTS.CATEGORY}
            Descrption={CONSTANTS.DESCRIPTION}
            CreatedOn={CONSTANTS.Create_On}
            Status={CONSTANTS.STATUS}
            ExpiryDate={CONSTANTS.Expiry_Date}
            PostedOn={CONSTANTS.POSTED_On}
          />
        ) : null}
        {!loading ? (
          count !== 0 ? (
            <div className="custom-table">
              <DataTable
                customStyles={customStyles}
                noHeader={true}
                columns={columns}
                data={sortedData}
                pagination
                paginationPerPage={limit}
                paginationRowsPerPageOptions={[10, 20, 30]}
                paginationComponent={(props) => (
                  <CustomPaginaton
                    {...props}
                    currentPage={currentPage}
                    limit={limit}
                    filter={filter}
                    count={count}
                    index={index}
                    setIndex={setIndex}
                    onChangePage={(page) => {
                      setCurrentPage(page);
                    }}
                  />
                )}
              />
            </div>
          ) : !apiCalled && (filter.length !== 0 || search !== "") ? (
            <Typography className={STYLES.NO_SEARCH_DATA} variant="p">
              {CONSTANTS.NO_SUCH_DATA}
            </Typography>
          ) : (
            <CreateHotDeals />
          )
        ) : (
          <LoadingScreen />
        )}
      </Container>
    </Container>
  );
};
export default HotManagementDeals;
