import React, { useState } from "react";
import "./CustomSelect.css";

const CustomSelect = ({ sort, setSort, sorting, setSorting }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const optionsByCategory = [
    { id: 1, label: "Ascending", value: "ASC", checked: false },
    { id: 2, label: "Descending", value: "DESC", checked: false },
  ];
  const handleOptionClick = (value) => {
    // Handle the selected option, you can use it as needed
    console.log("Selected Option:", value);
    setSorting(value);
    setSelectedOption(null);
    setSort(value);
  };

  return (
    <div className="custom-sort">
      {optionsByCategory.map((option) => (
        <label
          className={`custom-label-sort ${
            option.value === selectedOption ? "selected" : ""
          }`}
          key={option.id}
          onClick={() => handleOptionClick(option.value)}
        >
          {option.label}
        </label>
      ))}
    </div>
  );
};

export default CustomSelect;
