/** @format */

import fetchWithHeaders from "../FetchInterceptor";
import {
  API_URL_RESET_PASSWORD_EMAIL,
  API_URL_SIGN_IN,
  ERROR_MESSAGES,
  RENEW_TOKEN,
  RESET_PASSWORD_REQUEST,
} from "./Constants";
import { BASE_URL } from "./api";

const getAccessToken = () => {
  return localStorage.getItem("accessToken");
};
export const signInUser = async (username, password, remember_me) => {
  try {
    console.log("inside function response of api");
    const response = await fetch(API_URL_SIGN_IN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },

      body: JSON.stringify({
        username: username,
        password: password,
        remember_me: remember_me,
      }),
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES.AUTH_FAILED + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};
export const ResetPasswordEmail = async (email) => {
  try {
    const response = await fetchWithHeaders(API_URL_RESET_PASSWORD_EMAIL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
      }),
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES.AUTH_FAILED + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

export const ResetPasswordRequestAPI = async (password, accessToken) => {
  try {
    const response = await fetch(RESET_PASSWORD_REQUEST, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify({
        password: password,
      }),
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES.AUTH_FAILED + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

export const RenewToken = async (refresh_token) => {
  try {
    const response = await fetch(RENEW_TOKEN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh_token: refresh_token,
      }),
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES.AUTH_FAILED + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};
export const handleSendPopupAPI = async (accessToken, apiUrl, data) => {
  try {
    const response = await fetchWithHeaders(apiUrl, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES.AUTH_FAILED + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};

export const uploadImage = async (data) => {
  const accessToken = getAccessToken();
  console.log("api//", accessToken, data);
  // const file = data.get("file");
  // console.log("api///", file);

  try {
    const response = await fetchWithHeaders(
      `${BASE_URL}hot-deals/upload/image`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
          // "Content-Type": "multipart/form-data",
        },
        body: {
          file: data,
        },
      }
    );

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES.AUTH_FAILED + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};
export const resetPassword = async (password, accessToken) => {
  try {
    const response = await fetchWithHeaders(`${BASE_URL}user/reset-password`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        password: password,
      }),
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES.AUTH_FAILED + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};
export const updatePassword = async (oldpassword, newpassword, accessToken) => {
  try {
    const response = await fetch(`${BASE_URL}user/update-password`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${accessToken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        old_password: oldpassword,
        new_password: newpassword,
      }),
    });

    if (response.ok) {
      return await response.json();
    } else {
      throw new Error(ERROR_MESSAGES.AUTH_FAILED + response.statusText);
    }
  } catch (error) {
    throw new Error(ERROR_MESSAGES.ERROR_AUTH + error.message);
  }
};
