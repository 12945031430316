/** @format */

import React from "react";
import BellIcon from "../../../Assets/Notification/Bell.svg";
import Typograpghy from "../../Atoms/Typography/Typography";

import { CONSTANTS, ROUTES } from "../../../Helpers/Constants";
import "./Style.css";
import Container from "../../Atoms/Container/Container";
import Button from "../../Atoms/Button/Button";
import { useNavigate } from "react-router-dom";
import { STYLES } from "../../../Helpers/StyleConstants";
const CreateNotification = () => {
  const navigate = useNavigate();
  const handleNotification = () => {
    navigate(`${ROUTES.PUSH_NOTIFICATION}${ROUTES.CREATE_NOTIFICATION}`);
  };
  return (
    <div className={STYLES.CreateNotification}>
      <img src={BellIcon} alt="" />
      <Typograpghy variant='h5'>{CONSTANTS.NOTIFICATION_TEXT}</Typograpghy>
      <Typograpghy className='NoNotificationDisplayed' variant='p'>
        {CONSTANTS.NOTIFICATION_DISPLAYED}
      </Typograpghy>
      <Container className='Create-notification-button-container'>
        <Button
          text={CONSTANTS.NOTIFICATION_BUTTON}
          className='Create-notification-button'
          onClick={handleNotification}
        />
      </Container>
    </div>
  );
};

export default CreateNotification;
