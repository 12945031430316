/** @format */

// /** @format */

// import React, { useState, useEffect } from "react";
// import "./CustomSelectWithoutStore.css";
// import { GET_CATEGORIES_ENDPOINT } from "../../../Helpers/Constants";
// import LoadingScreen from "../LoadingScreen/LoadingScreen";
// import fetchWithHeaders from "../../../FetchInterceptor";
// const CustomSelectWithoutStore = ({
//   setFilter,
//   CheckBoxArray,
//   setCheckBoxArray,
// }) => {
//   const [loading, setLoading] = useState(true);
//   const [optionsByCategory, setOptionsByCategory] = useState([
//     {
//       category: "Category",
//       options: [],
//     },
//   ]);
//   useEffect(() => {
//     const fetchData = async () => {
//       try {
//         const response = await fetchWithHeaders(GET_CATEGORIES_ENDPOINT);
//         console.log(response, "response data");
//         const result = await response.json();
//         console.log(result, "------result---------");
//         setOptionsByCategory((prevOptions) => [
//           {
//             ...prevOptions[0],
//             options: result?.data || [],
//           },
//           ...prevOptions.slice(1),
//         ]);
//         setLoading(false);
//       } catch (error) {
//         console.error("Error fetching data:", error);
//       }
//     };
//     fetchData();
//   }, []);

//   const [selectedOptions, setSelectedOptions] = useState([]);

//   useEffect(() => {
//     setSelectedOptions(() =>
//       optionsByCategory.map((category) => ({
//         category: category.category,
//         options: category.options.map((option) => ({
//           ...option,
//           checked: CheckBoxArray.includes(option.id),
//           checkedByUser: false,
//         })),
//       }))
//     );
//   }, [optionsByCategory, CheckBoxArray]);

//   const handleCheckboxChange = (category, optionId) => {
//     const updatedSelectedOptions = [...selectedOptions];
//     console.log(selectedOptions, "========selected option=====");
//     const selectedCategoryIndex = updatedSelectedOptions.findIndex(
//       (cat) => cat.category === category
//     );

//     const selectedOptionIndex = updatedSelectedOptions[
//       selectedCategoryIndex
//     ].options.findIndex((opt) => opt.id === optionId);
//     updatedSelectedOptions[selectedCategoryIndex].options[
//       selectedOptionIndex
//     ].checked =
//       !updatedSelectedOptions[selectedCategoryIndex].options[
//         selectedOptionIndex
//       ].checked;
//     updatedSelectedOptions[selectedCategoryIndex].options[
//       selectedOptionIndex
//     ].checkedByUser = true;
//     if (
//       !updatedSelectedOptions[selectedCategoryIndex].options[
//         selectedOptionIndex
//       ].checked
//     ) {
//       const updatedCheckBoxArray = CheckBoxArray.filter(
//         (id) => id !== optionId
//       );
//       setCheckBoxArray(updatedCheckBoxArray);
//     }
//     setSelectedOptions(updatedSelectedOptions);
//     const selectedValues = getSelectedValues();
//     setFilter(selectedValues);
//     // onClose();
//   };

//   const getSelectedValues = () => {
//     const selectedValues = [];

//     selectedOptions.forEach((category) => {
//       if (category.category === "Category") {
//         const selectedOptionsInCategory = category.options
//           .filter((opt) => opt.checked && opt.checkedByUser)
//           .map((opt) => opt.id);

//         if (selectedOptionsInCategory.length > 0) {
//           selectedValues.push({
//             type: "category_id",
//             values: selectedOptionsInCategory,
//           });
//           setCheckBoxArray(
//             selectedValues.length > 0 ? selectedValues[0].values : []
//           );
//         }
//       } else if (category.category === "Store") {
//         const selectedStoreValue = category.options
//           .filter((opt) => opt.checked)
//           .map((opt) => opt.value);

//         if (selectedStoreValue.length > 0) {
//           selectedValues.push({ type: "store", values: selectedStoreValue });
//         }
//       }
//     });
//     return selectedValues;
//   };

//   return (
//     <div className='custom-options'>
//       {!loading ? (
//         <>
//           {selectedOptions.map((category) => (
//             <div
//               key={category.category}
//               className='custom-catagory-without-store'>
//               <h4 className='catagory-title'>{category.category}</h4>
//               {category.options.map((option) => (
//                 <label className='custom-label' key={option.id}>
//                   <input
//                     type='checkbox'
//                     checked={option.checked}
//                     onChange={() =>
//                       handleCheckboxChange(category.category, option.id)
//                     }
//                   />
//                   {option.name}
//                 </label>
//               ))}
//             </div>
//           ))}
//         </>
//       ) : (
//         <LoadingScreen />
//       )}
//     </div>
//   );
// };

// export default CustomSelectWithoutStore;

import React, { useState, useEffect } from "react";
import "./CustomSelectWithoutStore.css";
import { GET_CATEGORIES_ENDPOINT } from "../../../Helpers/Constants";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import fetchWithHeaders from "../../../FetchInterceptor";
const CustomSelectWithoutStore = ({ setFilter, setCheckBoxArray,CheckBoxArray }) => {
  const [loading, setLoading] = useState(true);
  const [optionsByCategory, setOptionsByCategory] = useState([
    {
      category: "Category",
      options: [],
    },
  ]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchWithHeaders(GET_CATEGORIES_ENDPOINT);
        
        const result = await response.json();
       
        setOptionsByCategory((prevOptions) => [
          {
            ...prevOptions[0],
            options: result?.data || [],
          },
          ...prevOptions.slice(1),
        ]);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {
    setSelectedOptions(() =>
      optionsByCategory?.map((category) => ({
        category: category.category,
        options: category.options.map((option) => ({
          ...option,
          checked: CheckBoxArray && CheckBoxArray.includes(option.id),
        })),
      }))
    );
  
  }, [optionsByCategory,CheckBoxArray]);
  const handleCheckboxChange = (category, optionId) => {
   
    const updatedSelectedOptions = [...selectedOptions];
    const selectedCategoryIndex = updatedSelectedOptions.findIndex(
      (cat) => cat.category === category
    );
    
    const selectedOptionIndex = updatedSelectedOptions[
      selectedCategoryIndex
    ].options.findIndex((opt) => opt.id === optionId);
    updatedSelectedOptions[selectedCategoryIndex].options[
      selectedOptionIndex
    ].checked =
      !updatedSelectedOptions[selectedCategoryIndex].options[
        selectedOptionIndex
      ].checked;
  
    if (
      !updatedSelectedOptions[selectedCategoryIndex].options[
        selectedOptionIndex
      ].checked
    ) {
      const updatedCheckBoxArray =
        CheckBoxArray?.filter((id) => id !== optionId) ?? [];
      setCheckBoxArray(updatedCheckBoxArray);
    }
    setSelectedOptions(updatedSelectedOptions);
    const selectedValues = getSelectedValues();
    setFilter(selectedValues);
  };
  
  const getSelectedValues = () => {
    const selectedValues = [];
    selectedOptions.forEach((category) => {
      if (category.category === "Category") {
        const selectedOptionsInCategory = category.options
          .filter((opt) => opt.checked)
          .map((opt) => opt.id);
        if (selectedOptionsInCategory.length > 0) {
        
          selectedValues.push({
            type: "category_id",
            values: selectedOptionsInCategory,
          });
          setCheckBoxArray(
            selectedValues.length > 0 ? selectedValues[0].values : []
          );
         
        }
      } else if (category.category === "Store") {
        const selectedStoreValue = category.options
          .filter((opt) => opt.checked)
          .map((opt) => opt.value);
        if (selectedStoreValue.length > 0) {
          selectedValues.push({ type: "store", values: selectedStoreValue });
        }
      }
    });
    return selectedValues;
  };
  return (
    <div className='custom-options'>
      {!loading ? (
        <>
          {selectedOptions.map((category) => (
            <div
              key={category.category}
              className='custom-catagory-without-store'>
              <h4 className='catagory-title'>{category.category}</h4>
              {category.options.map((option) => (
                <label className='custom-label' key={option.id}>
                  <input
                    type='checkbox'
                    checked={option.checked}
                    onChange={() =>
                      handleCheckboxChange(category.category, option.id)
                    }
                  />
                  {option.name}
                </label>
              ))}
            </div>
          ))}
        </>
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
};
export default CustomSelectWithoutStore;
