/** @format */

import React, { useState, useEffect } from "react";
import "./CreateNewDealForm.css";
import { useForm, Controller } from "react-hook-form";
import PostDealMessage from "../../Organisms/PostDealMessage/PostDealMessage";
import { yupResolver } from "@hookform/resolvers/yup";
import SubmitIcon from "../../../Assets/Icons/Submit.svg";
import {
  GET_CATEGORIES_ENDPOINT,
  POST_DEAL_ENDPOINT,
  ROUTES,
} from "../../../Helpers/Constants";
import { useNavigate } from "react-router-dom";
import InputField from "../../Atoms/InputField/InputField";
import SelectField from "../../Atoms/SelectField/SelectField";
import Container from "../../Atoms/Container/Container";
import { defaultValues, schema } from "./FormSchema";
import { CONSTANTS } from "./Constants";
import fetchWithHeaders from "../../../FetchInterceptor";

const CreateNewDealForm = () => {
  const [data, setData] = useState({});

  const [categoryData, setCategoryData] = useState([]);

  const {
    control,
    handleSubmit,
    setValue,
    register,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchWithHeaders(GET_CATEGORIES_ENDPOINT);
        const result = await response.json();

        setCategoryData(
          result.data.map((item) => ({ value: item.id, label: item.name }))
        );
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [setValue]);

  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setIsOpen(true);
    setData({
      title: data?.Title,
      store: data?.Store.value,
      promo_code: data?.PromoCode,
      use_code: data?.UseCode,
      link: data?.Link,
      image_link: data?.ImageLink,
      date: data?.Date,
      category_id: data?.Category.value,
    });
  };
  const [isOpen, setIsOpen] = useState(false);
  const StoreData = [
    { value: "Ebay", label: "Ebay" },
    { value: "Amazon", label: "Amazon" },
    { value: "AliBaba", label: "AliBaba" },
  ];

  return (
    <div className='deal-form-container'>
      <div className='form-container'>
        <form onSubmit={handleSubmit(onSubmit)} className='deal-form'>
          <h2>Create new Normal Deals</h2>

          <InputField
            name='Title'
            register={register}
            field='Title'
            errors={errors}
            label={CONSTANTS.TITLE}
            type={CONSTANTS.TEXT}
            showAsterisk={true}
            maxLength={168}
          />

          <Controller
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <SelectField
                value={value}
                label={"Category"}
                placeholder={"Category"}
                error={error?.message}
                options={categoryData}
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(e);
                }}
              />
            )}
            name={"Category"}
            control={control}
          />
          <Controller
            render={({
              field: { onChange, onBlur, value },
              fieldState: { error },
            }) => (
              <SelectField
                value={value}
                label={"Store"}
                placeholder={"Store"}
                error={error?.message}
                options={StoreData}
                onBlur={onBlur}
                onChange={(e) => {
                  onChange(e);
                }}
              />
            )}
            name={"Store"}
            control={control}
          />

          <InputField
            name={CONSTANTS.DATE}
            register={register}
            field={CONSTANTS.DATE}
            errors={errors}
            label={CONSTANTS.DATE}
            type={"date"}
            minDate={new Date().toISOString().split("T")[0]}
            showAsterisk={true}
          />
          <InputField
            name={CONSTANTS.PROMO_CODE}
            register={register}
            field={CONSTANTS.PROMO_CODE}
            errors={errors}
            label={CONSTANTS.PROMO_CODE_TITLE}
            type={CONSTANTS?.TEXT}
            showAsterisk={true}
            maxLength={15}
          />
          <InputField
            name={CONSTANTS.LINK}
            register={register}
            field={CONSTANTS.LINK}
            errors={errors}
            label={CONSTANTS.LINK}
            type={CONSTANTS?.TEXT}
            showAsterisk={true}
          />
          <InputField
            name={CONSTANTS.IMAGE_LINK}
            register={register}
            field={CONSTANTS.IMAGE_LINK}
            errors={errors}
            label={CONSTANTS.IMAGE_LINK_TITLE}
            type={CONSTANTS?.URL}
            showAsterisk={true}
          />

          <InputField
            name={CONSTANTS.USE_CODE}
            register={register}
            field={CONSTANTS.USE_CODE}
            errors={errors}
            label={CONSTANTS.USE_CODE_TITLE}
            type={CONSTANTS?.TEXT}
            showAsterisk={true}
            maxLength={15}
          />

          <Container className='footer-button'>
            <button type='submit' className='create-new-deal-send-button'>
              Send
            </button>
            <button
              onClick={() => {
                navigate(ROUTES?.DASHBOARD);
              }}
              type='button'
              className='create-new-deal-cancel-button'>
              Cancel
            </button>
          </Container>
        </form>
      </div>
      {isOpen && (
        <PostDealMessage
          setIsOpen={setIsOpen}
          data={data}
          heading={CONSTANTS.POST_DEAL_HEADING}
          text={CONSTANTS.POST_NEW_DEAL_MESSAGE}
          apiUrl={POST_DEAL_ENDPOINT}
          src={SubmitIcon}
          navigateToOnSend={ROUTES.DASHBOARD}
          navigateToOnCancel={ROUTES.NORMAL_DEALS_FORM}
        />
      )}
    </div>
  );
};

export default CreateNewDealForm;
