/** @format */

import React from "react";
import Typography from "../../Atoms/Typography/Typography";
import "./NormalDealsDetails.css";
import { STYLES } from "../../../Helpers/StyleConstants";
const NormalDealsDetails = ({
  Title,
  Category,
  Store,
  Date,
  Promocode,
  Link,
  ImageLnk,
  UseCode,
}) => {
  return (
    <div className={STYLES.Normal_Deals}>
      <ul className={STYLES.Normal_Deals_details}>
        <li>
          <Typography variant='p' className={STYLES.Normal_Deals_Details_Text}>
            {Title}
          </Typography>
        </li>
        <li>
          <Typography variant='p' className={STYLES.Normal_Deals_Details_Text}>
            {Category}
          </Typography>
        </li>
        <li>
          <Typography variant='p' className={STYLES.Normal_Deals_Details_Text}>
            {Store}
          </Typography>
        </li>

        <li>
          <Typography variant='p' className={STYLES.Normal_Deals_Details_Text}>
            {Date}
          </Typography>
        </li>
        <li>
          <Typography variant='p' className={STYLES.Normal_Deals_Details_Text}>
            {Promocode}
          </Typography>
        </li>
        <li>
          <Typography variant='p' className={STYLES.Hot_Deal_Detail_Text}>
            {Link}
          </Typography>
        </li>
        <li>
          <Typography variant='p' className={STYLES.Hot_Deal_Detail_Text}>
            {ImageLnk}
          </Typography>
        </li>
        <li>
          <Typography variant='p' className={STYLES.Hot_Deal_Detail_Text}>
            {UseCode}
          </Typography>
        </li>
        {/* </div> */}
      </ul>
    </div>
  );
};

export default NormalDealsDetails;
