/** @format */

import React, { createContext, useContext, useState } from "react";
const AlertContext = createContext();
export const useAlertContext = () => useContext(AlertContext);
export const AlertProvider = ({ children }) => {
  const [showAlert, setShowAlert] = useState(true);
  const [alert, setShowalert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const showAlertMessage = (message) => {
    setAlertMessage(message);
    setShowAlert(true);
  };
  const hideAlert = () => {
    setShowAlert(false);
    setShowalert(false);
    setAlertMessage("");
  };

  const contextValues = {
    showAlert,
    alertMessage,
    showAlertMessage,
    hideAlert,
    setShowAlert,
    setShowalert,
    alert,
  };
  return (
    <AlertContext.Provider value={contextValues}>
      {children}
    </AlertContext.Provider>
  );
};
