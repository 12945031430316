/** @format */

import * as yup from "yup";
import { CONSTANTS } from "./Constants";

const re =
  /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm;
export const schema = yup.object().shape({
  Title: yup
    .string()
    .required("Title is required")
    .max(20, CONSTANTS.TITLE_MAX),
  Category: yup
    ?.object({
      id: yup?.string(),
      is_visible: yup?.boolean(),
      label: yup?.string(),
    })
    ?.required("Please choose a category")
    ?.typeError("Please choose a category"),
  Store: yup
    ?.object({
      id: yup?.string(),
      is_visible: yup?.boolean(),
      label: yup?.string(),
    })
    ?.required("Please choose a store")
    ?.typeError("Please choose a store"),
  Date: yup
    .date()
    .typeError("Please Select a Date")
    .required("Please Select a Date"),
  PromoCode: yup
    .string()
    .required("Promo Code is required")
    .max(10, CONSTANTS.PROMOCODE_MAX),
  Link: yup
    .string()
    .matches(re, "URL is not valid")
    .required("Link is required"),
  ImageLink: yup.string().url().required("Image Link is required"),
  UseCode: yup
    .string()
    .required("Use Code is required")
    .max(10, CONSTANTS.USECODE_MAX),
});

export const defaultValues = {
  Title: "",
  Category: "",
  Store: "",
  Date: "",
  PromoCode: "",
  Link: "",
  ImageLink: "",
  UseCode: "",
};
