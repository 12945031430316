/** @format */
import React, {  useState } from "react";
import Container from "../Container/Container";
import "./InputField.css";
import { STYLES } from "../../../Helpers/StyleConstants";
const InputField = ({
  name,
  register,
  field,
  errors,
  label,
  type,
  onChange,
  maxLength,
  minDate,
  data,
  inputdisable,
  showIcon=true,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const InputFieldClass = errors[name]
    ? STYLES.INPUT_FIELD_ERROR
    : inputdisable
    ? `${STYLES.INPUT_FORM} inputFieldDisabled`
    : STYLES.INPUT_FORM;
  const InputFieldStyling = inputdisable ? "inputFieldDisabled" : "inputField";
  return (
    <Container >
      <label>{label}</label>
      <div
        style={{ display: "flex", alignItems: "center" }}
        className={InputFieldClass}
      >
        <input
          type={showPassword ? "text" : type}
          {...register(field)}
          onChange={onChange}
          maxLength={maxLength}
          min={minDate}
          className={InputFieldStyling}
          value={data}
        />
        {type === "password" && showIcon && (
          
          <i
            className={`fa ${showPassword ? "fa-eye-slash" : "fa-eye"} `}
            aria-hidden="true"
            // style={EyeElemet}
            onClick={handleTogglePassword}
          ></i>
        )}
      </div>
      {errors[name]?.message && (
        <h5 className={STYLES.ERROR_MESSAGE}>{errors[name]?.message}</h5>
      )}
    </Container>
  );
};
export default InputField;
