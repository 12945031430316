/** @format */

export const CONSTANTS = {
  TITLE: "Title",
  TEXT: "text",
  DATE: "Date",
  PROMO_CODE: "PromoCode",
  PROMO_CODE_TITLE: "Promo Code",
  LINK: "Link",
  URL: "url",
  IMAGE_LINK: "ImageLink",
  IMAGE_LINK_TITLE: "Image Link",
  USE_CODE_TITLE: "Use Code",
  USE_CODE: "UseCode",
  TITLE_MAX: "Title is too long",
  PROMOCODE_MAX: "Promocode is too long",
  USECODE_MAX: "Use Code is too long",
  POST_DEAL_HEADING: "Post Deal",
  POST_NEW_DEAL_MESSAGE: "Are you sure you want to post this deal?"
};
