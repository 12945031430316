/** @format */

import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Image from "../../Atoms/Image";
import Typography from "../../Atoms/Typography/Typography";
import logo from "../../../Assets/Assets/logo.png";
import { navigationItems } from "../../../Helpers/NavigationItem";
import { ROUTES } from "../../../Helpers/Constants";
import "./Style.css";
import { STYLES } from "../../../Helpers/StyleConstants";
const SideBarItem = ({ isActive, icon, label, onClick }) => (
  <li
    className={`${STYLES.SideBarItem} ${isActive ? "active" : ""}`}
    onClick={onClick}
  >
    <Image
      className={STYLES.SidebarIcon}
      src={isActive ? icon.activeIcon : icon.inactiveIcon}
    />
    <Typography
      variant="p"
      className={`${STYLES.ActiveLink} ${isActive ? STYLES.ActiveText : ""}`}
    >
      {label}
    </Typography>
  </li>
);

const SideBar = () => {
  const [activeLink, setActiveLink] = useState();

  const handleItemClick = (link) => {
    setActiveLink(link);
  };

  useEffect(() => {
    if (window.location.pathname === "/Dashboard") {
      setActiveLink(ROUTES.DASHBOARD);
    }
  }, []);
  useEffect(() => {
    const currentPath = window.location.pathname;
    const defaultActiveLink = navigationItems.find(
      (item) => item.path === currentPath
    );
    if (defaultActiveLink) {
      setActiveLink(defaultActiveLink.path);
    }
  }, []);

  return (
    <>
      <Image className={STYLES.LogoImg} src={logo} />
      <ul className={STYLES.Navbar}>
        {navigationItems.map(({ path, label, icon }, index) => (
          <Link key={index} to={path}>
            <SideBarItem
              isActive={activeLink === path}
              icon={icon}
              label={label}
              onClick={() => handleItemClick(path)}
            />
          </Link>
        ))}
      </ul>
    </>
  );
};

export default SideBar;
