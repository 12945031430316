import { Outlet, Navigate } from 'react-router-dom'
import Layout from '../components/Molecules/Layout/Layout'

const PrivateRoutes = () => {
  let auth = {'token':localStorage.getItem('accessToken') }
    return(
      auth?.token ?<Layout> <Outlet/></Layout> : <Navigate to="/"/>
    )
}

export default PrivateRoutes