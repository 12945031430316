/** @format */

import React, { useState, useEffect } from "react";
import "./CustomSelectWithCatagory.css";
import {
  GET_CATEGORIES_ENDPOINT,
  GET_STORE_ENDPOINT,
} from "../../../Helpers/Constants";
import LoadingScreen from "../LoadingScreen/LoadingScreen";
import fetchWithHeaders from "../../../FetchInterceptor";
const CustomSelectWithCatagory = ({
  setFilter,

  CheckBoxArray,
  setCheckBoxArray,
}) => {
  const [loading, setLoading] = useState(true);

  const [optionsByCategory, setOptionsByCategory] = useState([
    {
      category: "Category",
      options: [],
    },
    {
      category: "Store",
      options: [],
    },
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetchWithHeaders(GET_CATEGORIES_ENDPOINT);
        const response1 = await fetchWithHeaders(GET_STORE_ENDPOINT);
        const result = await response.json();
        const storeResult = await response1.json();
        setOptionsByCategory(() => [
          {
            category: "Category",
            options: result?.data || [],
          },
          {
            category: "Store",
            options: storeResult?.data || [],
          },
        ]);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const [selectedOptions, setSelectedOptions] = useState([]);
  useEffect(() => {
    setSelectedOptions(() =>
      optionsByCategory?.map((category) => ({
        category: category.category,
        options: category.options.map((option) => ({
          ...option,
          checked: CheckBoxArray && CheckBoxArray.includes(option.id),
        })),
      }))
    );
  }, [optionsByCategory, CheckBoxArray]);
  const handleCheckboxChange = (category, optionId) => {
    const updatedSelectedOptions = [...selectedOptions];
    const selectedCategoryIndex = updatedSelectedOptions.findIndex(
      (cat) => cat.category === category
    );

    const selectedOptionIndex = updatedSelectedOptions[
      selectedCategoryIndex
    ].options.findIndex((opt) => opt.id === optionId);
    updatedSelectedOptions[selectedCategoryIndex].options[
      selectedOptionIndex
    ].checked =
      !updatedSelectedOptions[selectedCategoryIndex].options[
        selectedOptionIndex
      ].checked;

    if (
      !updatedSelectedOptions[selectedCategoryIndex].options[
        selectedOptionIndex
      ].checked
    ) {
      const updatedCheckBoxArray =
        CheckBoxArray?.filter((id) => id !== optionId) ?? [];
      setCheckBoxArray(updatedCheckBoxArray);
    }
    setSelectedOptions(updatedSelectedOptions);
    const selectedValues = getSelectedValues();
    setFilter(selectedValues);
  };
  const getSelectedValues = () => {
    const selectedValues = [];
    let hasStore = false;
    let hasCategory = false;
    selectedOptions.forEach((category) => {
      if (category.category === "Category") {
        const selectedOptionsInCategory = category.options
          .filter((opt) => opt.checked)
          .map((opt) => opt.id);
        if (selectedOptionsInCategory.length > 0) {
          selectedValues.push({
            type: "category_id",
            values: selectedOptionsInCategory,
          });
          setCheckBoxArray(
            selectedValues.length > 0 ? selectedValues[0].values : []
          );
        }
      } else if (category.category === "Store") {
        const selectedStoreValues = category.options
          .filter((opt) => opt.checked)
          .map((opt) => opt.id);
        if (selectedStoreValues.length > 0) {
          selectedValues.push({ type: "store", values: selectedStoreValues });

          setCheckBoxArray(selectedValues[0].values);
        }
      }

      if (
        category.category === "Category" &&
        category.options.some((opt) => opt.checked)
      ) {
        hasCategory = true;
      } else if (
        category.category === "Store" &&
        category.options.some((opt) => opt.checked)
      ) {
        hasStore = true;
      }
    });

    if (hasStore && hasCategory) {
      setCheckBoxArray(
        selectedValues
          .flat()
          .map((item) => item.values)
          .flat()
      );
    }

    return selectedValues;
  };
  return (
    <div className="custom-options">
      {!loading ? (
        <>
          {selectedOptions.map((category) => (
            <div key={category.category} className="custom-catagory">
              <h4 className="catagory-title">{category.category}</h4>
              {category.options.map((option) => (
                <label className="custom-label" key={option.id}>
                  <input
                    type="checkbox"
                    checked={option.checked}
                    onChange={() => {
                      console.log("handle checkbox change");
                      handleCheckboxChange(category.category, option.id);
                    }}
                  />
                  {option.name}
                </label>
              ))}
            </div>
          ))}
        </>
      ) : (
        <LoadingScreen />
      )}
    </div>
  );
};
export default CustomSelectWithCatagory;
