/** @format */

import React from "react";
import "./CustomAlert.css";
import CheckCircle from "../../../Assets/Icons/CheckCircle.svg";
import Cross from "../../../Assets/Icons/Cross.svg";
import { STYLES } from "../../../Helpers/StyleConstants";


const CustomAlert = ({ message, onClose }) => {

  return (
    <div className="custom-alert-container">
      <div className={STYLES.ALERT_MESSAGE_FORGOT_PASSWORD}>
        <img src={CheckCircle} alt="Logo" />
        <p>{message}</p>
      </div>
      <div className={STYLES.ICON_CROSS_FORGOT_PASSWORD}>
        <img src={Cross} alt="Logo" onClick={onClose} />
      </div>
    </div>
  );
};

export default CustomAlert;
