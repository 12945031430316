/** @format */
import React from "react";
import "./PostDealMessage.css";
import { useNavigate } from "react-router-dom";
import Image from "../../Atoms/Image/Image";
import Button from "../../Atoms/Button/Button";
import Container from "../../Atoms/Container/Container";
import { handleSendPopupAPI } from "../../../Helpers/Functions";
import { useAlertContext } from "../../../Helpers/UseContext";
const PostDealMessage = ({
  setIsOpen,
  data,
  text,
  heading,
  apiUrl,
  src,
  navigateToOnSend,
  navigateToOnCancel,
  sendButtonText,
  action,
}) => {
  const navigate = useNavigate();
  const { setShowalert } = useAlertContext();
  const getAccessToken = () => {
    return localStorage.getItem("accessToken");
  };

  const handleSend = async () => {
    try {
      const accessToken = getAccessToken();
      const responseData = await handleSendPopupAPI(accessToken, apiUrl, data);
      responseData && navigate(navigateToOnSend);
    } catch (error) {
      console.log("error", error);
    }
  };
  const handleResetPopup = () => {
    setShowalert(true);
    setIsOpen(false);
  };
  const handleClick = () => {
    if (action === "Send") {
      handleSend();
    } else if (action === "resetPopup") {
      handleResetPopup();
    }
  };
  const handleCancel = () => {
    setIsOpen(false);
    navigate(navigateToOnCancel);
  };

  return (
    <Container className="popup">
      <Container className="post-deal-message-container">
        <Container className="post-deal-message-box">
          <Container className="post-deal-header">
            <Image src={src} alt="Logo" className="post-deal-logo" />
            <Container className="post-deal-header-text">
              <Container className="post-deal-message-text">
                {heading}
              </Container>
              <Container className="post-deal-confirmation-text">
                {text}
              </Container>
            </Container>
          </Container>
          <Container className="post-deal-footer">
            <Button
              text={sendButtonText}
              className="deal-send-button"
              onClick={handleClick}
            />
            <Button
              text="Cancel"
              className="deal-cancel-button"
              onClick={handleCancel}
            />
          </Container>
        </Container>
      </Container>
    </Container>
  );
};

export default PostDealMessage;
