/** @format */

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import InputField from "../../Atoms/InputField/InputField";
import Container from "../../Atoms/Container/Container";
import {
  CONSTANTS,
  PROFILE_ENDPOINT,
} from "../../../Helpers/Constants";
import "./Setting.css";
import Typography from "../../Atoms/Typography/Typography";
import Button from "../../Atoms/Button/Button";
import ResetPassword from "../../Molecules/ResetPassword/ResetPassword";
import fetchWithHeaders from "../../../FetchInterceptor";
import { STYLES } from "../../../Helpers/StyleConstants";
const Setting = () => {
  const [isError, setIsError] = useState(false);
  const [resetPassword, setresetPassword] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState(false);
  const [DealsData, setDealsData] = useState({});
  const [autofillData, setAutofillData] = useState(null);

  const defaultValues = {
    Email: "",
    Password: "",
  };

  const schema = yup.object().shape({
    Email: yup
      .string()
      .email(CONSTANTS.CORRECT)
      .required(CONSTANTS.EMAIlVALUE)
      .max(100, CONSTANTS.EMAIL_MAX)
      .min(4, CONSTANTS.EMAIL_MIN),
    Password: yup
      .string()
      .required(CONSTANTS.PASSWORD_VALUE)
      .min(6, CONSTANTS.PASSWORD_MIN)
      .max(100, CONSTANTS.PASSWORD_MAX),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });
  const formClass =
    isError || ErrorMessage ? STYLES.LOGIN_FORM_ERROR : STYLES.Setting_Form;
  const InputFieldClass = isError
    ? STYLES.INPUT_FIELD_ERROR
    : STYLES.INPUT_FORM;

  useEffect(() => {
    if (autofillData) {
      setValue("Email", autofillData.email);
      setValue("Password", autofillData.password);
    }
  }, [autofillData, setValue]);
  const onSubmit = async (data) => {
    try {
      setresetPassword(true);
    } catch (error) {
      if (error.message.includes("Authentication failed")) {
        setErrorMessage("Enter Correct Credentials");
      }
    }
  };
  const getAccessToken = () => {
    return localStorage.getItem("accessToken");
  };
  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const accessToken = getAccessToken();

        const response = await fetchWithHeaders(PROFILE_ENDPOINT, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });
        const result = await response.json();
        setAutofillData({
          email: result?.email,
          password: result?.password_secret?.slice(0, 10),
        });
        setDealsData(result);
      } catch (error) {
        console.error("Error fetching data for deals:", error);
      }
    };

    fetchDeals();
  }, []);

  return (
    <Container className={STYLES.MainContent}>
      {resetPassword ? (
        <ResetPassword setresetPassword={setresetPassword} />
      ) : (
        <>
          <Typography variant="p" className={STYLES.Setting_Title}>
            {CONSTANTS.SETTING}
          </Typography>
          <form className={formClass} onSubmit={handleSubmit(onSubmit)}>
            <InputField
              name="Email"
              register={register}
              field="Email"
              errors={errors}
              label={CONSTANTS.EMAIL_ADDRESS}
              className={InputFieldClass}
              setIsError={setIsError}
              type={CONSTANTS.TEXT}
              data={DealsData?.email}
              inputdisable={true}
            />
            <InputField
              name="Password"
              register={register}
              field="Password"
              errors={errors}
              label={CONSTANTS.PASSWORD}
              className={InputFieldClass}
              setIsError={setIsError}
              type="password"
              data={DealsData?.password_secret?.slice(0, 10)}
              inputdisable={true}
              showIcon={false}
            />

            {!resetPassword && (
              <Button
                className={STYLES.Change_Password}
                text={CONSTANTS.CHANGE_PASSWORD}
              />
            )}
            {ErrorMessage && <p className="error-color">{ErrorMessage}</p>}
          </form>
        </>
      )}
    </Container>
  );
};

export default Setting;
