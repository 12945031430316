/** @format */

import React from "react";

const Container = ({ children, style, className, value, id,onClick }) => {
  return (
    <div id={id} className={className} style={style} value={value} onClick={onClick}>
      {children}
    </div>
  );
};

export default Container;
