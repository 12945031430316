export const STYLES = {
    REMEMBER_ME: "remember-me",
    LOGIN_FORM_ERROR: "login-form-errors",
    LOGIN_FORM: "login-form",
    INPUT_FIELD_ERROR: "input-form-field-error",
    INPUT_FORM: "input-form-field",
    LOGIN_CONTAINER: "login-container",
    FORGET_BUTTON: "forgotbutton",
    SIGNIN_FORM_SUBMIT: "signin-form-submit",
    SING_BOTTOM_TEXT: "signin-form-bottom-text",
    LOGIN_CHECKBOX: "login-form-checkbox",
    FORM_BUTTON: "formbuttons",
    LOGO: "logo",
    LOGIN_FORGET_ERROR: "login-form-errors-forgot-pass",
    LOGIN_FORGET: "login-form-forgot-pass",
    INPUT_FIELD_FORGET_ERROR: "input-form-field-error-forgot-pass",
    INPUT_FILED_FORGET: "input-form-field-forgot-pass",
    LOGIN_FORGET_CONTAINER: "login-container-forgot-pass",
    LOGO_FORGET: "logo-forgot-pass",
    RESET_FORGET_PASSWORD: "reset-password-email",
    FORGET_PASSWORD_BOTTOM_TEXT: "forgot-pass-bottom-text",
    LOGIN_CREATE_PASSWORD_ERROR: "login-form-errors-create-pass",
    CREATE_LOGIN_PASSWORD: "login-form-create-pass",
    CREATE_NEWPASSWORD_CONTAINER: "create-newpass-container",
    Main: "Main",
    LogoImg: "LogoImg",
    SidebarIcon: "SideBarIcon",
    FullScreen: "FullScreen",
    MainContent: "MainContent",
    Header: "Header",
    Siderbar: "Sidebar",
    Navbar: "Navbar",
    SideBarItem: "SidebarItem",
    SearchComponent: "Search",
    SearchIcon: "SearchIcon",
    CustomText: "CustomText",
    SearchField: "SearchField",
    InputField: "InputField",
    NotificationDescription: "NotificationDescription",
    CreateNotification: "CreateNotification",
    NotifictationMessage: "NotificationMessage",
    ActiveLink: "ActiveLink",
    ActiveText: "ActiveText",
    TextArea: "text_area",
    TextAreaHotDeals: "Text_Area_Hot_Deals",
    TextArea_Label: "textarea-label",
    CreateNotification_Heading: "create-notification-heading",
    Notification_Button: "notification-button",
    CREATENOTIFICATIONBUTTON: "Create-notification-button",
    SendNotification_button: "Send-notification-button",
    CancelNotification_button: "Cancel-notification-button",
    ERROR_MESSAGE: "error-message",
    BreadCrums: "Breadcrums",
    UserProfile: "user-profile",
    ProfileDetail: "profiledetail",
    ProfileName: "profile-name",
    Logout: "logout",
    ASTERIK_SYMBOL: "asterik-symbol",
    DASHBOARD_CREATE_NEW_DEAL_BUTTON: "dashboard-create-new-deal-button",
    ICON_CROSS_FORGOT_PASSWORD: "icon-cross-forgot-password",
    ALERT_MESSAGE_FORGOT_PASSWORD: "alert-message-forgot-password",
    Hot_NEW_DEAL_BUTTON: "hot-create-new-deal-button",
    Create_hot_Deals_Button: "empty-table-hot-new-deal-button ",
    SYNC_WITH_GOOGLE_SHEET_BUTTON_STYLE: "sync-button ",
    Create_Empty_Hot_Deal_button: "empty-hot-deal-button",
    Hot_Deal_deatils: "Hot_Deals_details",
    Hot_Deal_Detail_Text: "hot-deal-details-text",
    User_Manager_Detail_Text: "user-manager-details-text",
    User_Manager_Detail: "user-manager-details",
    Create_Hot_Deal_Message: "create-new-hot-deal-message-container",
    Hot_Deal_Tag_Logo: "hotdeal-tag-logo ",
    No_deal: "no-deals",
    NO_USER: "no-user",
    Add_New_Hot_DEAL: "Add-new-hot-deal",
    User_Manager_Search: "User-manager-search",
    DashBoardNewDealButton: "dashboard-new-deal-Button",
    Change_Password: "change-password-link",
    Setting_Title: "setting-title",
    Setting_Form: "setting-form",
    Change_Password_Title: "change-password-title",
    Reset_Password_Error: "reset-password-error ",
    Reset_Password_form: "reset-form-password",
    Reset_Button: "reset-button",
    Change_Reset_button: "change-reset-button",
    No_User_to_Display_Message: "no-user-to-display-message",
    NO_SEARCH_DATA: "no-data-on-search",
    Setting_Color: "seeting-background-color",
    Search_box: "search-box",
    Display_Screen: "display-screen",
    ProfilePictue: "profile-picture",
    Notification_Details: "notification-details",
    Push_Notification_Detail_Text: "push-notification_detail_text",
    Add_New_Notification: "add-new-notifcation",
    Normal_Deals: "normal-deals",
    Normal_Deals_Details_Text: "normal-deal-details-text",
    Normal_Deals_details: "normal-deals-details",
    PROFILE_PIC: "profile-pic",
    Add_New_Normal_Deal: "add-new-normal-deal",
    Normal_Deal_Top_Header: "normal-deal-top-header-text",
  };
 