/** @format */

import React from "react";
import Typography from "../../Atoms/Typography/Typography";
import "./Style.css";
import { STYLES } from "../../../Helpers/StyleConstants";
const NotificationDetails = ({ Descrption, SentOn, Status }) => {
  return (
    <div className={STYLES.Notification_Details}>
      {/* <div> */}
        <ul className={STYLES.NotificationDescription}>
          {/* <div className='PushNotificationFirstTwoColumns'> */}
          <li>
            <Typography
              variant='p'
              className={STYLES.Push_Notification_Detail_Text}>
              {Descrption}
            </Typography>
          </li>

          <li>
            <Typography
              variant='p'
              className={STYLES.Push_Notification_Detail_Text}>
              {SentOn}
            </Typography>
          </li>
          <li>
            <Typography
              variant='p'
              className={STYLES.Push_Notification_Detail_Text}>
              {Status}
            </Typography>
          </li>
          {/* </div> */}
        </ul>
      {/* </div> */}
    </div>
  );
};

export default NotificationDetails;
