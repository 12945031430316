/** @format */
import React, { useState } from "react";
import * as yup from "yup";

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import "./ResetPassword.css";
import Button from "../../Atoms/Button/Button";
import Container from "../../Atoms/Container/Container";
import InputField from "../../Atoms/InputField/InputField";
import Typography from "../../Atoms/Typography/Typography";
import { CONSTANTS } from "../../../Helpers/Constants";
import { useAlertContext } from "../../../Helpers/UseContext";
import { resetPassword, updatePassword } from "../../../Helpers/Functions";
import { STYLES } from "../../../Helpers/StyleConstants";

const ResetPassword = ({ setresetPassword }) => {
  const [isError, setIsError] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [ErrorMessage, setErrorMessage] = useState(false);
  const navigate = useNavigate();
  const { showAlertMessage } = useAlertContext();
  const defaultValues = {
    Password: "",
  };
  const schema = yup.object().shape({
    OldPassword: yup
      .string()
      .required(CONSTANTS.OLD_PASSWORD_REQUIRED)
      .min(6, CONSTANTS.PASSWORD_MIN)
      .max(100, CONSTANTS.PASSWORD_VALUE),
    Password: yup
      .string()
      .required(CONSTANTS.PASSWORD_VALUE)
      .min(6, CONSTANTS.PASSWORD_MIN)
      .max(100, CONSTANTS.PASSWORD_MAX),
    ConfirmPassword: yup
      .string()
      .oneOf([yup.ref("Password"), null], CONSTANTS.PASSWORD_DO_NOT_MATCH) // Change the constant here
      .required(CONSTANTS.CONFIRM_PASSWORD_REQUIRED),
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    const {
      ConfirmPassword: newpassword,
      OldPassword: oldpassword,
      Password: password,
    } = data;

    if (password === confirmPassword) {
      try {
        const accessToken = localStorage.getItem("accessToken");

        const updatePasswordData = await updatePassword(
          oldpassword,
          newpassword,
          accessToken
        );
        if (updatePasswordData) {
          try {
            const responseData = await resetPassword(password, accessToken);
            responseData&&localStorage.removeItem("accessToken");
            handleShowAlert();
            navigate("/");
          } catch (error) {
            console.log("err", error);
          }
        } else {
        }
      } catch (error) {
        if (error.message.includes("Authentication failed")) {
          setErrorMessage(CONSTANTS.OLD_PASSWORD_WRONG);
        }
      }
    } else {
      setPasswordsMatch(false);
    }
  };
  const formClass = isError
    ? STYLES.Reset_Password_Error
    : STYLES.Reset_Password_form;
  const InputFieldClass = isError
    ? STYLES.INPUT_FIELD_ERROR
    : STYLES.INPUT_FORM;

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  const handleCancel = () => {
    // navigate(ROUTES.SETTING);
    setresetPassword(false);
  };
  const handleShowAlert = () => {
    showAlertMessage(CONSTANTS.RESET_PASSWORD_MESSAGE);
  };

  return (
    <Container>
      <Typography variant="p" className={STYLES.Change_Password_Title}>
        {CONSTANTS.CHANGE_PASSWORD}
      </Typography>
      <form className={formClass} onSubmit={handleSubmit(onSubmit)}>
        <Container className="reset-inputfield">
          <InputField
            name="OldPassword"
            register={register}
            field="OldPassword"
            errors={errors}
            label={CONSTANTS.OLD_PASSWORD}
            className={InputFieldClass}
            setIsError={setIsError}
            type="password"
          />
          <InputField
            name="Password"
            register={register}
            field="Password"
            errors={errors}
            label={CONSTANTS.PASSWORD_LABEL}
            className={InputFieldClass}
            setIsError={setIsError}
            type="password"
          />
          <InputField
            name="ConfirmPassword"
            register={register}
            field="ConfirmPassword"
            errors={errors}
            label={CONSTANTS.CONFIRM_PASSWORD}
            className={InputFieldClass}
            setIsError={setIsError}
            type="password"
            onChange={handleConfirmPasswordChange}
          />
        </Container>
        {!passwordsMatch && (
          <p className="error-color">{CONSTANTS.PASSWORD_DO_NOT_MATCH}</p>
        )}
        {ErrorMessage && (
          <h5 className={STYLES.ERROR_MESSAGE}>{ErrorMessage}</h5>
        )}

        <Container className={STYLES.Reset_Button}>
          <Button
            type="submit"
            className={STYLES.Change_Reset_button}
            text={CONSTANTS.CHANGE}
          />
          <Button
            type="button"
            onClick={handleCancel}
            className={STYLES.CancelNotification_button}
            text={CONSTANTS.CANCEL}
          />
        </Container>
      </form>
    </Container>
  );
};
export default ResetPassword;
