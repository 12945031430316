/** @format */

import React from "react";
import { Route, BrowserRouter, Routes } from "react-router-dom";

import { ROUTES } from "./Helpers/Constants";
import SignIn from "./components/Organisms/SignIn";
import PrivateRoutes from "./Helpers/PrivateRoute";
import { AlertProvider } from "./Helpers/UseContext";
import Dashboard from "./components/Organisms/Dashboard";
import Setting from "./components/Organisms/Setting/Setting";
import CreateNormalDeals from "./components/Organisms/CreateNormalDeals";
import HotDealsForm from "./components/Molecules/HotDealsForm/HotDealsForm";
import ResetPassword from "./components/Molecules/ResetPassword/ResetPassword";
import UserManagement from "./components/Organisms/UserManagement/UserManagement";
import CreateNewPassword from "./components/Organisms/CreateNewPassword/CreateNewPassword";
import CreateNewDealForm from "./components/Molecules/CreateNewDealForm/CreateNewDealForm";
import HotManagementDeals from "./components/Organisms/HotManagementDeals/HotManagementDeals";
import NotificationScreen from "./components/Organisms/NotificationScreen/NotificationScreen";
import ForgotPasswordScreen from "./components/Organisms/ForgotPasswordScreen/ForgotPasswordScreen";
import CreateNotificationScreen from "./components/Organisms/CreateNotificationScreen/CreateNotficationScreen";
import "react-toastify/dist/ReactToastify.css";
// import "./App.css";

const App = () => {
  return (
    <div className="App">
      <BrowserRouter>
        <AlertProvider>
          <Routes>
            <Route path="/" element={<SignIn />} />
            <Route
              path={ROUTES?.Forget_Password}
              element={<ForgotPasswordScreen />}
            />
            <Route
              path={ROUTES?.CREATE_NEW_PASSWORD}
              element={<CreateNewPassword />}
            />
            <Route
              element={
                // Use Layout as a wrapper for all other routes
                <PrivateRoutes />
              }
            >
              <Route
                path={ROUTES?.CREATE_NORMAL_DEALS}
                element={<CreateNormalDeals />}
              />

              <Route
                path={`${ROUTES?.HOT_DEALS}${ROUTES?.HOT_DEALS_FORM}`}
                element={<HotDealsForm />}
              />
              <Route
                path={ROUTES?.PUSH_NOTIFICATION}
                element={<NotificationScreen />}
              />
              <Route
                path={ROUTES?.USER_MANAGEMENT}
                element={<UserManagement />}
              />
              <Route path={ROUTES?.SETTING} element={<Setting />} />
              <Route
                path={ROUTES?.HOT_DEALS}
                element={<HotManagementDeals />}
              />
              <Route path={ROUTES?.DASHBOARD} element={<Dashboard />} />
              {/* <Route
                    path={ROUTES?.DASHBOARD}
                    element={<CreateNewDeal />}
                  /> */}
              <Route
                path={ROUTES?.NORMAL_DEALS_FORM}
                element={<CreateNewDealForm />}
              />
              {/* <Route path={ROUTES?.POSTDEALMESSAGE} element={<PostDealMessage/>} /> */}
              <Route
                path={ROUTES?.PUSH_NOTIFICATION}
                element={<NotificationScreen />}
              />
              <Route
                path={ROUTES?.USER_MANAGEMENT}
                element={<UserManagement />}
              />
              <Route path={ROUTES?.SETTING} element={<Setting />} />
              <Route
                path={`${ROUTES?.PUSH_NOTIFICATION}${ROUTES?.CREATE_NOTIFICATION}`}
                element={<CreateNotificationScreen />}
              />
              <Route
                path={`${ROUTES?.SETTING}${ROUTES?.Reset_Password}`}
                element={<ResetPassword />}
              />
            </Route>
          </Routes>
        </AlertProvider>
      </BrowserRouter>
    </div>
  );
};
export default App;
