/** @format */

import * as yup from "yup";
import { CONSTANTS } from "./Constants";
import { format } from "date-fns-tz";
// eslint-disable-next-line no-useless-escape
const re = /^(((ftp|http|https):\/\/)?(www\.)?(?!.*(ftp|http|https|www\.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+(\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?)?)|((ftp|http[s]?)\:\/\/(?:www\.)?(?!.*(ftp|http|https|www\.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+([\/?].*)?)$/gm;
export const schema = yup.object().shape({
  Deal_Name: yup.string().required("Deal Name is required"),
  Category: yup
    ?.object({
      id: yup?.string(),
      is_visible: yup?.boolean(),
      label: yup?.string(),
    })
    ?.required("Please choose a category")
    ?.typeError("Please choose a category"),
  code: yup.string(),

  ImageLink: yup.string().url().required("Image Link is required"),
  Expiry_Date: yup
    .date()
    .typeError("Please Select a Date")
    .required("Please Select a Date"),

  Link1: yup
    .string()
    .required("Link is required")
    .matches(re, "URL is not valid"),

  Link2: yup.string().matches(re, "URL is not valid"),
  Link3: yup.string().matches(re, "URL is not valid"),
  postOn: yup?.object().shape({
    facebook: yup?.boolean().required("Please select at least one platform"),
    app: yup?.boolean().required("Please select at least one platform"),
    telegram: yup?.boolean().required("Please select at least one platform"),
  }),
  Description: yup
    .string()
    .required("Description is required")
    .max(10000, CONSTANTS?.DESCRIPTION_MAX),
});
export const getFormattedDate = (date) => {
  const easternTimeZone = "America/New_York";

  // Format the date to YYYY-MM-DD format
  const formattedDate = format(date, "yyyy-MM-dd", {
    timeZone: easternTimeZone,
  });

  return formattedDate;
};

export const defaultValues = {
  Deal_Name: "",
  Category: "",
  code: "",
  Expiry_Date: getFormattedDate(new Date()),
  Link1: "",
  Link2: "",
  Link3: "",
  postOn: {
    facebook: false,
    app: false,
    telegram: false,
  },
  Description: "",
  ImageLink: "",
};
