/** @format */

import React from "react";
import "rsuite/dist/rsuite-no-reset.min.css";
import "./CreateNormalDeals.css";
// import FocusExploreIcon from "../../../Assets/BrownIcon/Tag.svg";
// import FocusGiftIcon from "../../../Assets/BrownIcon/Gift.svg";
// import FocusBellIcon from "../../../Assets/BrownIcon/Bell.svg";
// import FocusUserIcon from "../../../Assets/BrownIcon/Users.svg";
// import FocusSettingIcon from "../../../Assets/BrownIcon/Cog.svg";

const CreateNormalDeals = () => {
  // const focusIcons = {
  //   img1: FocusExploreIcon,
  //   img2: FocusGiftIcon,
  //   img3: FocusBellIcon,
  //   img4: FocusUserIcon,
  //   img5: FocusSettingIcon,
  // };

  return <></>;
};

export default CreateNormalDeals;
