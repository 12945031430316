import * as yup from "yup";
import { CONSTANTS } from "../../../Helpers/Constants";

export const defaultValues = {
  Email: "",
  Password: "",
};

export const schema = yup.object().shape({
  Password: yup
    .string()
    .required(CONSTANTS.PASSWORD_VALUE)
    .min(6, CONSTANTS.PASSWORD_MIN)
    .max(100, CONSTANTS.PASSWORD_VALUE),
});
