/** @format */

import React, { Fragment, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Typography from "../Typography/Typography";
import { CONSTANTS, PROFILE_ENDPOINT } from "../../../Helpers/Constants";
import HomeIcon from "../../../Assets/Notification/Home.svg";
import ArrowIcon from "../../../Assets/Notification/Arrow.svg";
import ProfilePictue from "../../../Assets/Icons/profile_pic.png";
import Container from "../Container/Container";
import Button from "../Button/Button";
import "./BreadCrums.css";
import Image from "../Image";
import fetchWithHeaders from "../../../FetchInterceptor";
import { STYLES } from "../../../Helpers/StyleConstants";
const Breadcrumbs = ({ crumbs, breadCrumbs }) => {
  const [userName, setUserName] = useState([]);
  const navigate = useNavigate();
  const HandleLogout = () => {
    localStorage.clear();
    navigate("/");
  };
  const getAccessToken = () => {
    return localStorage.getItem("accessToken");
  };
  useEffect(() => {
    const fetchDeals = async () => {
      try {
        const accessToken = getAccessToken();
        const url = PROFILE_ENDPOINT;
        const response = await fetchWithHeaders(url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });
        const result = await response.json();
        setUserName(result?.name);
      } catch (error) {
        console.error("Error fetching data for deals:", error);
      }
    };
    fetchDeals();
  }, []);
  return (
    <Container className={STYLES.BreadCrums}>
      <Container className={STYLES.Header}>
        {crumbs.map((crumb, index) => (
          <Fragment key={index}>
            {index === 0 && <Image src={HomeIcon} />}
            <Image src={ArrowIcon} alt="Breadcrumb ArrowIcon" />
            {index !== crumbs.length - 1 && (
              <Link to={`${breadCrumbs.slice(0, index + 1).join("/")}`}>
                <Typography
                  variant="p"
                  className={`${
                    index === crumbs.length - 1 ? STYLES.ActiveLink : ""
                  } ${
                    index === 0 && crumbs.length > 1 ? STYLES.ActiveLink : ""
                  }`}
                >
                  {crumb}
                </Typography>
              </Link>
            )}
            {index === crumbs.length - 1 && (
              <Typography variant="p" className={`${STYLES.ActiveLink}`}>
                {crumb}
              </Typography>
            )}
          </Fragment>
        ))}
      </Container>
      <Container className={STYLES.UserProfile}>
        {userName ? (
          <Container className={STYLES?.PROFILE_PIC}>{userName[0]}</Container>
        ) : (
          <Image
            className={STYLES.ProfilePictue}
            src={ProfilePictue}
            alt="Profile Picture"
          />
        )}
        <Container className={STYLES.ProfileDetail}>
          <Typography variant="p" className={STYLES.ProfileName}>
            {userName}
          </Typography>
          <Button
            className={STYLES.Logout}
            text={CONSTANTS.LOGOUT}
            onClick={HandleLogout}
          />
        </Container>
      </Container>
    </Container>
  );
};
export default Breadcrumbs;
