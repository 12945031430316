/** @format */

import React from "react";
import Select from "react-select";
import DropdownIndicator from "./DropdownIndicator";
import { STYLES } from "../../../Helpers/StyleConstants";

const getCustomStyles = (error) => {
  return {
    option: (provided, state) => {
      return {
        ...provided,
        cursor: "pointer",
        color: state.isFocused ? "#000000" : "#0A0D14",
        background: state.isFocused ? "var(--Color-Gray-200, #E5E7EB)" : "#fff",
        fontWeight: state.isFocused ? "500" : "normal",
      };
    },
    menu: (provided) => {
      return {
        ...provided,
        backgroundColor: "#fff",
      };
    },

    singleValue: (provided) => {
      return {
        ...provided,
        color: "black",
      };
    },

    placeholder: (provided) => {
      return {
        ...provided,
        color: "grey",
      };
    },
    control: (provided, state) => {
      return {
        ...provided,

        borderRadius: 6,
        border: state?.isFocused
          ? "1px solid #9E6C3C"
          : "1px solid var(--Color-Gray-300, #D1D5DB)",
        background: "var(--Color-Gray-White, #FFF)",

        /* shadow/sm */
        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",

        width: "100%",
        height: "41px",
        cursor: "pointer",
      };
    },
  };
};
const SelectField = ({ label, error, name, value, isSearchable, ...rest }) => {
  return (
    <div className='relative'>
      <label>{label}</label>
      <Select
        styles={getCustomStyles(error)}
        value={value}
        {...rest}
        components={{
          DropdownIndicator: DropdownIndicator,
          IndicatorSeparator: null,
        }}
        isSearchable={isSearchable}
      />
      {error && <h5 className={STYLES.ERROR_MESSAGE}>{error}</h5>}
    </div>
  );
};

export default SelectField;
