/** @format */

import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import "./CreateNewPassword.css";
import logo from "../../../Assets/logo.png";
import Image from "../../Atoms/Image/Image";
import Button from "../../Atoms/Button/Button";
import Container from "../../Atoms/Container/Container";
import InputField from "../../Atoms/InputField/InputField";
import { CONSTANTS} from "../../../Helpers/Constants";
import { ResetPasswordRequestAPI } from "../../../Helpers/Functions";
import { defaultValues, schema } from "./formSchema";
import { STYLES } from "../../../Helpers/StyleConstants";


const CreateNewPassword = () => {

  const navigate = useNavigate();
  const [token, setToken] = useState(null);
  const [password, setPassword] = useState("");
  const [isError, setIsError] = useState(false);
  const [ErrorMessage, setErrorMessage] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);



  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
    reValidateMode: "onChange",
    defaultValues: defaultValues,
    resolver: yupResolver(schema),
  });
  useEffect(() => {
    const urlParams = new URLSearchParams(window?.location?.search);
    const token = urlParams?.get(CONSTANTS?.TOKEN);
    setToken(token);
  }, []);
  const onSubmit = async (data, e) => {
    if (password === confirmPassword) {
      try {
        const accessToken = token;
        const responseData = await ResetPasswordRequestAPI(
          password,
          accessToken
        );
        responseData&&navigate("/");
      } catch (error) {
        console.error(error);
        if (error.message.includes("Authentication failed")) {
          setErrorMessage(CONSTANTS.FORGET_PASSWORD_LINK_EXP);
        }
      }
    } else {
      setPasswordsMatch(false);
    }
  };

  const formClass = isError
    ? STYLES.LOGIN_CREATE_PASSWORD_ERROR
    : STYLES.CREATE_LOGIN_PASSWORD;
  const InputFieldClass = isError
    ? STYLES.INPUT_FIELD_ERROR
    : STYLES.INPUT_FORM;

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
  };
  return (
    <Container className={STYLES.CREATE_NEWPASSWORD_CONTAINER}>
      <Image src={logo} alt='Logo' className={STYLES.LOGO} />
      <h2>Create a new password </h2>
      <form className={formClass} onSubmit={handleSubmit(onSubmit)}>
        <InputField
          name='Password'
          register={register}
          field='Password'
          errors={errors}
          label={CONSTANTS.PASSWORD_LABEL}
          className={InputFieldClass}
          setIsError={setIsError}
          type='password'
          onChange={handlePasswordChange}
        />
        <InputField
          name='Confirm Password'
          register={register}
          field='Confirm Password'
          errors={errors}
          label={CONSTANTS.CONFIRM_PASSWORD}
          className={InputFieldClass}
          setIsError={setIsError}
          type='password'
          onChange={handleConfirmPasswordChange}
        />
        {!passwordsMatch ? (
          <p className='error-color'>{CONSTANTS.PASSWORD_DO_NOT_MATCH}</p>
        ) : (
          <p className='error-color'></p>
        )}
        <Button
          type={CONSTANTS.TYPE}
          className={STYLES.SIGNIN_FORM_SUBMIT}
          text={CONSTANTS.CONFIRM}
        />
        {ErrorMessage && <p className='error-color'>{ErrorMessage}</p>}
      </form>
      <p className={STYLES.SING_BOTTOM_TEXT}>{CONSTANTS.SIGN_IN_BOTTOM_TEXT}</p>
    </Container>
  );
};
export default CreateNewPassword;
