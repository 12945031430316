/** @format */

import React, { useEffect, useState } from "react";
import NotificationDetails from "../../Molecules/NotificationScreen/NotificationDetails";
import CreateNotification from "../../Molecules/CreateNotification/CreateNotification";
import SearchField from "../../Atoms/Search/SearchIField";
import LoadingScreen from "../../Atoms/LoadingScreen/LoadingScreen";

import {
  CONSTANTS,
  CREATE_NOTIFICATION,
  ROUTES,
} from "../../../Helpers/Constants";
import "./Style.css";
import Container from "../../Atoms/Container/Container";
import Button from "../../Atoms/Button/Button";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { columns } from "./NotificationScreenColumns";
import CustomPagination from "../../Molecules/CustomPagination";
import Typography from "../../Atoms/Typography/Typography";
import fetchWithHeaders from "../../../FetchInterceptor";
import { STYLES } from "../../../Helpers/StyleConstants";
const NotificationScreen = () => {
  const [apiCalled, setApiCalled] = useState(false);
  const [count, setCount] = useState(0);
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(true);
  const [filter, setFilter] = useState([]);
  // const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState("");
  const [index, setIndex] = useState(false);
  const getAccessToken = () => {
    return localStorage.getItem("accessToken");
  };
  const [notificationsData, setNotificationsData] = useState([]);
  const navigate = useNavigate();
  const handleNotification = () => {
    navigate(`${ROUTES?.PUSH_NOTIFICATION}${ROUTES?.CREATE_NOTIFICATION}`);
  };

  const customStyles = {
    headCells: {
      style: {
        color: "#1F2937",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "32px",
        marginLeft: "20px",
      },
    },
    headRow: {
      style: {
        background: "#F9FAFB",
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
        
      },
    },
  };
  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const accessToken = getAccessToken();
  
        // Correct the way you define the URL
        const url = CREATE_NOTIFICATION;
  
        const api_url = search ? `${url}?filter[search]=${search}` : url;
  
        const response = await fetchWithHeaders(api_url, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
        });
  
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new Error("Response is not in JSON format");
        }
  
        const result = await response.json();
        setCount(result?.totalCount);
        setNotificationsData(result?.data);
  
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data for users:", error);
        setLoading(false);
      }
    };
    if (!apiCalled || search) {
      fetchNotifications();
    }
  }, [search, apiCalled]);

  const handleSearch = (newSearch) => {
    setSearch(newSearch);
    setApiCalled(false);
  };

  let limit = 5;
  return (
    <Container className={STYLES.FullScreen}>
      <Container className={STYLES.MainContent}>
        <Container className={STYLES.Add_New_Notification}>
          <SearchField
            text={CONSTANTS.PUSH_NOTIFICATION}
            onSearch={handleSearch}
            setSearch={setSearch}
            setSort={setSort}
            sort={sort}
            setFilter={setFilter}
            sortingValue={false}
          />

          {(count !== 0 && !apiCalled && !apiCalled && !search) ||
          (!apiCalled && filter) ? (
            <Button
              text={CONSTANTS.NOTIFICATION_BUTTON}
              className="Create-notification-button1"
              onClick={handleNotification}
            />
          ) : null}
        </Container>
        {count === 0 && !apiCalled ? (
          <NotificationDetails
            Descrption={CONSTANTS.DESCRIPTION}
            SentOn={CONSTANTS.SENT_ON}
            Status={CONSTANTS.STATUS}
          />
        ) : null}

        {!loading ? (
          count !== 0 ? (
            <div className="custom-table">
              <DataTable
                customStyles={customStyles}
                noHeader={true}
                columns={columns}
                data={notificationsData}
                pagination
                paginationPerPage={limit}
                paginationRowsPerPageOptions={[10, 20, 30]}
                // onChangePage={(page) => setCurrentPage(page)}
                paginationComponent={(props) => (
                  <CustomPagination
                    {...props}
                    count={count}
                    limit={limit}
                    index={index}
                    setIndex={setIndex}
                  />
                )}
              />
            </div>
          ) : !apiCalled && !search ? (
            <CreateNotification />
          ) : (
            <Typography className={STYLES.NO_SEARCH_DATA} variant="p">
              {CONSTANTS.NO_SUCH_DATA}
            </Typography>
          )
        ) : (
          <LoadingScreen />
        )}
      </Container>
    </Container>
  );
};

export default NotificationScreen;
