/** @format */

import React from "react";

const Typography = ({ variant, children, className }) => {
  let style;

  switch (variant) {
    case "h1":
      style = { fontSize: "24px", fontWeight: "600", fontFamily: "Inter" };
      break;
    case "h5":
      style = {
        fontSize: "24px",
        fontWeight: "700",
        fontFamily: "Inter",
        color: "",
      };
      break;
    case "p":
      style = {
        fontSize: "14px",
        fontWeight: "500",
        fontFamily: "Inter",
        color: "#6B7280",
      };
       // eslint-disable-next-line
    default:
      style = {};
  }
  return (
    <div style={style} className={className}>
      {children}
    </div>
  );
};

export default Typography;
