/** @format */

import React from "react";
import TagIcon from "../../../Assets/Icons/Tag.svg";
import Typography from "../../Atoms/Typography/Typography";
import Button from "../../Atoms/Button/Button";
import Image from "../../Atoms/Image";
import { CONSTANTS } from "../../../Helpers/Constants";
import "./SyncData.css";
import { STYLES } from "../../../Helpers/StyleConstants";

const SyncData = ({ handleClick }) => {
  return (
    <div>
      <div className={STYLES.Create_Hot_Deal_Message}>
        <Image src={TagIcon} alt="Logo" className={STYLES.Hot_Deal_Tag_Logo} />
        <Typography variant="h5">{CONSTANTS.NO_DEAL}</Typography>
        <Typography className={STYLES.No_deal} variant="p">
          {CONSTANTS.NO_DEAL_TO_BE_DISPLAYED}
        </Typography>

        <Button
          className={STYLES.SYNC_WITH_GOOGLE_SHEET_BUTTON_STYLE}
          text={CONSTANTS.SYNC_WITH_GOOGLE_SHEET_TEXT}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default SyncData;
